import { Chip } from '@mui/material';
import { FC } from 'react';

interface FilterChipProps {
    label: string;
    selected: boolean;
    onDelete?: () => void;
    onClick: () => void;
}

export const FilterChip: FC<FilterChipProps> = ({
    label,
    selected,
    onClick,
    onDelete,
}) => {
    return (
        <Chip
            label={label}
            color={selected ? 'primary' : 'default'}
            variant={selected ? 'filled' : 'outlined'}
            onDelete={onDelete}
            onClick={onClick}
        />
    );
};
