import axios from 'axios';
import {ADMIN_HOST, LOCAL_STORAGE_KEY} from 'src/config';

axios.create({
  baseURL: `${ADMIN_HOST}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const authProvider = {
  login: async ({username, password}) => {
    return Promise.reject();
  },
  logout: () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    return Promise.resolve();
  },
  checkAuth: () =>
      localStorage.getItem(LOCAL_STORAGE_KEY) ?
          Promise.resolve() :
          Promise.reject(),
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getIdentity: () => {
    let item = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    return Promise.resolve({
      id: item.id,
      fullName: item.name,
    });
  },
  getPermissions: () => Promise.resolve(''),
};

export default authProvider;