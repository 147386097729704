import { baseApi } from './index';
import {
    CreateFineDto,
    CreateFineForm,
    Fine,
    FineHistoryItem,
    FineReason,
    FineResponse,
} from '../models/Fine';
import { AppealsStatusFilterState } from '../store/appeals/appealsStatusFilter';
import { joinByKeys } from '../models/util';

type FineSearchParams = {
    userId: number;
    status: AppealsStatusFilterState;
};

export const fineApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getFines: builder.query<Fine[], FineSearchParams>({
            query: ({ userId, status }) => ({
                url: 'fines',
                params: {
                    creator_user_id: userId,
                    status: joinByKeys(status),
                },
            }),
            transformResponse: (res: FineResponse) =>
                res.items.map((fine) => {
                    // address normalization: adding spaces after , if there are none
                    fine.location.name = fine.location.name.replace(
                        /, */g,
                        ', '
                    );

                    return fine;
                }),
            providesTags: ['Fines'],
        }),
        getFineHistory: builder.query<FineHistoryItem[], number>({
            query: (fineId) => `fines/${fineId}/history`,
        }),
        getFineReasons: builder.query<FineReason[], string>({
            query: (search) => ({
                url: 'fine_reasons',
                params: {
                    search,
                },
            }),
        }),
        uploadPhoto: builder.mutation<string, File>({
            query: (file) => {
                const form = new FormData();

                form.append('file', file);

                return {
                    url: 'upload',
                    method: 'POST',
                    body: form,
                };
            },
            transformResponse: (res: { key: string }) => res.key,
        }),
        createFine: builder.mutation<
            void,
            { form: CreateFineForm; photos: string[] }
        >({
            query: ({ form, photos }) => {
                const body: CreateFineDto = {
                    coauthor_user_id: form.coauthor?.id,
                    fine_reason_id: form.reason.id,
                    fine_type_id: form.reason.auto_fine_type_id,
                    photos,
                    location_id: form.location.id,
                    description: form.comment,
                };

                return {
                    url: 'fines',
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['Fines'],
        }),
    }),
});

export const {
    useGetFinesQuery,
    useGetFineHistoryQuery,
    useLazyGetFineReasonsQuery,
    useUploadPhotoMutation,
    useCreateFineMutation,
} = fineApi;
