import { createListenerMiddleware, isAllOf, isAnyOf } from '@reduxjs/toolkit';
import { authApi } from '../../api/auth';
import {
    logout,
    refresh,
    REFRESH_TOKEN_LOCAL_STORAGE_KEY,
    TOKEN_LOCALSTORAGE_KEY,
} from '../auth';

export const updateLocalStorageListenerMiddleware = createListenerMiddleware();

updateLocalStorageListenerMiddleware.startListening({
    matcher: isAnyOf(authApi.endpoints.auth.matchFulfilled, logout, refresh),
    effect: (action) => {
        const isRefresh = isAllOf(refresh);
        const isAuth = isAllOf(authApi.endpoints.auth.matchFulfilled);
        const isLogout = isAllOf(logout);

        if (isAuth(action) || isRefresh(action)) {
            const { refresh_token, access_token } = action.payload;

            localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, access_token);
            localStorage.setItem(
                REFRESH_TOKEN_LOCAL_STORAGE_KEY,
                refresh_token
            );
        }

        if (isLogout(action)) {
            localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY);
            localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
        }
    },
});
