import instance from "./api";
import {StatusPool} from "../util";

class FinesService {
  async createFine(
    {
      passPhoto,
      violationPhoto,
      fineTypeID,
      fineReasonID,
      locationID,
      description,
      coauthorID,
    }) {

    const params = {
      "fine_type_id": fineTypeID,
      "fine_reason_id": fineReasonID,
      "location_id": locationID,
      "description": description,
      "pass_photo": passPhoto,
      "violation_photo": violationPhoto,
    }
    if (coauthorID !== "") {
      params["coauthor_user_id"] = coauthorID
    }

    const res = await instance.post("/fines", params)
    return res.data
  }

  async updateFine(
    {
      id,
      status,
      code,
      fineTypeID,
      fineReasonID,
      comment,
      locationID,
      coauthorID,
    }) {

    let params = {
      "fine_type_id": fineTypeID,
      "fine_reason_id": fineReasonID,
      "status": status,
      "code": parseInt(code),
      "comment": comment,
      "location_id": locationID,
    }
    if (coauthorID !== "") {
      params["coauthor_user_id"] = coauthorID
    }
    const res = await instance.put(`/fines/${id}`, params)
    return res.data
  }

  async getFinesByUser(userID) {
    const params = {}
    if (userID) {
      params['creator_user_id'] = userID;
    }

    const res = await instance.get(`/fines`, {params: params})
    return res.data
  }

  async getFines(params) {
    const res = await instance.get(`/fines`, {params: params})
    return res.data
  }

  async exportFines(params) {
    const res = await instance.get(`/fines/export`, {
      params: params,
      responseType: "blob",
    })
    return res.data
  }

  async getStats() {
    const res = await instance.get(`/fines/stats`)
    return res.data
  }

  async getFine(id) {
    const res = await instance.get(`/fines/${id}`)
    return res.data
  }

  async getFineHistory(id) {
    const res = await instance.get(`/fines/${id}/history`)
    return res.data
  }

  async getFinesForCollection() {
    const params = {
      'page_size': 999,
      'for_collection': true,
    }
    const res = await instance.get(`/fines`, {params: params})
    return res.data
  }

  async collectFines(ids = []) {
    const res = await instance.post(`/fines/collect`, {
      "fine_ids": ids,
    })
    return res.data
  }

  async pendingCollections() {
    const res = await instance.get(`/fines/pending_collections`)
    return res.data
  }

  async collections(params) {
    const res = await instance.get(`/fines/collections`, {params: params})
    return res.data
  }

  async collectionsExport(params) {
    const res = await instance.get(`/fines/collections/export`, {
      params: params,
      responseType: "blob",
    })
    return res.data
  }

  async completeCollections(ids = []) {
    const res = await instance.post(`/fines/complete_collections`, {
      "ids": ids,
    })
    return res.data
  }

  async findFineReasons(name) {
    const res = await instance.get(`/fine_reasons/?search=${name}`)
    return res.data
  }
}

export default new FinesService();