import {BooleanField, SimpleShowLayout, Show, TextField} from "react-admin";
import React from "react";


export const LocationShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" label="ID"/>
      <TextField source="building" label="Здание"/>
      <TextField source="floor" label="Этаж"/>
      <TextField source="line" label="Линия"/>
      <TextField source="place_number" label="Точка"/>
      <TextField source="zone" label="Зона"/>
      <BooleanField source="active" label="Активна"/>
    </SimpleShowLayout>
  </Show>
);