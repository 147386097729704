import { createListenerMiddleware, isAllOf, isAnyOf } from '@reduxjs/toolkit';
import { setAppealsStatusFilter } from '../appeals/appealsStatusFilter';
import { fineApi } from '../../api/fine';
import { RootState } from '../index';
import { setPassesStatusFilter } from '../passes/passesStatusFilter';
import { passesApi } from '../../api/passes';

export const refetchOnFilterChangeListenerMiddleware =
    createListenerMiddleware();

refetchOnFilterChangeListenerMiddleware.startListening({
    matcher: isAnyOf(setAppealsStatusFilter, setPassesStatusFilter),
    effect(action, api) {
        const state = api.getState() as RootState;
        const isPassesChanges = isAllOf(setPassesStatusFilter);
        const isAppealsChanges = isAllOf(setAppealsStatusFilter);

        if (isAppealsChanges(action)) {
            api.dispatch(
                fineApi.endpoints.getFines.initiate(
                    {
                        userId: state.auth.userId!,
                        status: state.appealsStatusFilterSlice,
                    },
                    {
                        forceRefetch: true,
                    }
                )
            );
        }

        if (isPassesChanges(action)) {
            if (isAppealsChanges(action)) {
                api.dispatch(
                    passesApi.endpoints.getPasses.initiate(
                        {
                            userId: state.auth.userId!,
                            status: state.passesStatusFilterSlice,
                        },
                        {
                            forceRefetch: true,
                        }
                    )
                );
            }
        }
    },
});
