import { useEffect, useState } from 'react';
import { JobTitle } from '../models/Passes';
import { useLazyGetJobsQuery } from '../api/jobs';

export function useJobTitleSuggest() {
    const [searchJobTitle, setSearchJobTitle] = useState('');
    const [jobTitle, setJobTitle] = useState<JobTitle | null>(null);
    const [triggerJobTitleSearch, { data: jobTitleSuggest }] =
        useLazyGetJobsQuery();

    useEffect(() => {
        if (searchJobTitle.length === 0) {
            return;
        }

        triggerJobTitleSearch(searchJobTitle);
    }, [searchJobTitle, triggerJobTitleSearch]);

    return {
        searchJobTitle,
        setSearchJobTitle,
        jobTitle,
        setJobTitle,
        jobTitleSuggest,
    };
}
