import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PassFilter } from '../../models/Passes';

export type PassStatusFilterState = {
    [key in PassFilter]: boolean;
};

export const passesStatusFilterSlice = createSlice({
    name: 'passesStatusFilterSlice',
    initialState: {} as PassStatusFilterState,
    reducers: {
        set(
            state,
            action: PayloadAction<{ status: PassFilter; state: boolean }>
        ) {
            state[action.payload.status] = action.payload.state;
        },
    },
});

export const { set: setPassesStatusFilter } = passesStatusFilterSlice.actions;
