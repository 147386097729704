import {Button, Grid, Typography} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function LoadingWrapper({loading, error, errorText, children}) {
  if (loading) {
    return (
      <Grid
        container
        style={{height: "100%"}}
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Typography variant="h5">Загрузка...</Typography>
      </Grid>
    )
  }

  if (error) {
    return (
      <Grid
        container
        style={{height: "100%"}}
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Typography color="error" variant="h5"
                    sx={{mb: 1}}>{errorText ? errorText : "Ошибка при загрузке"}</Typography>
        <Button
          variant="outlined"
          onClick={(e) => {
            window.location.reload();
          }}
        >
          <RefreshIcon/> Перезагрузить
        </Button>
      </Grid>
    )
  }

  return children ? children : <div/>
}