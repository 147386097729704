import React, { useEffect, useState } from 'react';
import { useLocation } from '../../../hooks/useLocation';
import {
    Control,
    Controller,
    FieldPathByValue,
    FieldValues,
    useWatch,
} from 'react-hook-form';
import { useFormReset } from '../../../hooks/useFormReset';
import {
    Autocomplete,
    Button,
    IconButton,
    Stack,
    TextField,
} from '@mui/material';
import { requiredRule } from '../../../models/Form';
import { Location } from '../../../models/Location';
import { QrCode } from '@mui/icons-material';
import { LocationsService } from 'src/services/locations_service';
import { QrScanner } from './QrScanner';
import { Loader } from './Loader';
import { locationApi, useLazyGetLocationByNumberQuery } from 'src/api/location';

type LocationFormProps<
    TFieldValues extends FieldValues,
    TPath extends FieldPathByValue<TFieldValues, Location>
> = {
    control: Control<TFieldValues>;
    name: TPath;
    qrCode?: boolean;
    setValue?: any;
};

export const LocationForm = <
    TFieldValues extends FieldValues,
    TPath extends FieldPathByValue<TFieldValues, Location>
>({
    control,
    name,
    qrCode = false,
    setValue,
}: LocationFormProps<TFieldValues, TPath>) => {

    const { area, line, place } = useLocation();
    const value = useWatch({
        control,
        name
    });
    const [ showScanner, setShowScanner ] = useState<boolean>(false);
    const [ showLoader, setShowLoader ] = useState<boolean>(false);
    const [ capture, setCapture ] = useState<string>('');

    const [ getLocationByNumber ] = useLazyGetLocationByNumberQuery();

    useFormReset(value, () => {
        area.setArea(null);
        area.setSearchArea('');
    });

    const handleCapture = async (scanned: string) => {
        let parts = scanned.split(" ")
        if (parts.length > 0) {
            setCapture(parts[0]);
        }
    }

    useEffect(() => {
        if (capture) {
            setShowScanner(false);
            setShowLoader(true);

            const request = async () => {

                const { data } = await getLocationByNumber({
                    number: capture
                });

                if (!data) {
                    setShowLoader(false);
                    return;
                }

                area.setArea(data);

                line.setCapture(capture);
                line.setLine(data.line);

                place.setCapture(capture);
                place.setPlace(data);

                setValue(name, data);
                setShowLoader(false);
            }

            request();
        }
    }, [capture]);

    return (
        <Stack spacing={1}>
            {showLoader && <Loader/>}
            <Stack direction="row" spacing={1}>
                <Autocomplete
                    fullWidth
                    size="small"
                    id="area"
                    options={area.areaSuggest ?? []}
                    loadingText="Загрузка..."
                    noOptionsText="Ничего не найдено"
                    getOptionLabel={(option) =>
                        `${option.building} ${option.floor}`
                    }
                    onChange={(e, a) => {
                        area.setArea(a);
                    }}
                    isOptionEqualToValue={(o, v) =>
                        o.building === v.building && o.floor === v.floor
                    }
                    onInputChange={(e, v) => {
                        area.setSearchArea(v);
                    }}
                    value={area.area}
                    inputValue={area.searchArea}
                    renderInput={(params) => (
                        <TextField {...params} label="Здание,этаж*" />
                    )}
                />
                {qrCode && (
                    <>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{ minWidth: 'auto' }}
                            onClick={() => setShowScanner(true)}
                        >
                            <QrCode />
                        </Button>
                        {showScanner 
                            && <QrScanner
                                onClose={() => setShowScanner(false)}
                                onResult={handleCapture}
                            />
                        }
                    </>
                )}
            </Stack>

            <Autocomplete
                fullWidth
                size="small"
                id="line"
                options={line.linesSuggest ?? []}
                loadingText="Загрузка..."
                noOptionsText="Ничего не найдено"
                getOptionLabel={(option) => option}
                onChange={(e, p) => {
                    line.setLine(p);
                }}
                isOptionEqualToValue={(o, v) => o === v}
                onInputChange={(e, v) => {
                    line.setSearchLine(v);
                }}
                value={line.line}
                inputValue={line.searchLine}
                disabled={!area.area}
                renderInput={(params) => (
                    <TextField {...params} label="Линия*" />
                )}
            />

            <Controller
                name={name}
                control={control}
                rules={requiredRule}
                render={({
                    field: { onChange, ...field },
                    formState: { errors },
                }) => {
                    const errorMessage = (
                        errors[name]?.message ?? ''
                    ).toString();
                    return (
                        <Autocomplete
                            fullWidth
                            size="small"
                            id="location"
                            options={place.placeSuggest ?? []}
                            loadingText="Загрузка..."
                            noOptionsText="Ничего не найдено"
                            getOptionLabel={(option) => option.place_number}
                            onChange={(e, p) => {
                                onChange(p);
                                place.setPlace(p);
                            }}
                            isOptionEqualToValue={(o, v) => o.id === v.id}
                            onInputChange={(e, v) => {
                                place.setSearchPlace(v);
                            }}
                            value={place.place}
                            inputValue={place.searchPlace}
                            disabled={!line.line}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    {...field}
                                    label="Павильон*"
                                    error={!!errors.location}
                                    helperText={errorMessage}
                                />
                            )}
                        />
                    );
                }}
            />
        </Stack>
    );
};
