import React from 'react';

import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { Menu } from './menu/Menu';
import { useAuth } from '../../../hooks/useAuth';

export const Restricted = () => {
    const isAuth = useAuth();

    if (!isAuth) {
        return <Navigate to={'../login'} />;
    }

    return (
        <Box
            sx={{
                position: 'relative',
                height: '100vh',
                overflow: 'hidden',
            }}
        >
            <Outlet />
            <Box
                sx={{
                    position: 'fixed',
                    paddingBottom: '1rem',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: 84,
                    backgroundColor: '#fff',
                }}
            >
                <Menu />
            </Box>
        </Box>
    );
};
