import { Box, Stack, SvgIcon } from '@mui/material';
import React, { FC } from 'react';
import { motion } from 'framer-motion';

interface MenuItemProps {
    Icon: typeof SvgIcon;
    label: string;
    activeIndex: number;
    index: number;
    setActive: (index: number) => void;
}

export const MenuItem: FC<MenuItemProps> = ({
    Icon,
    label,
    index,
    activeIndex,
    setActive,
}) => {
    const color =
        index === activeIndex ? 'rgb(255,255,255)' : 'rgba(0,0,0,0.6)';

    return (
        <Stack
            className="noh"
            component="a"
            position="relative"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            width={1}
            onClick={() => setActive(index)}
        >
            <Icon
                component={motion.svg}
                initial={{
                    color,
                }}
                animate={{
                    color,
                }}
                transition={{
                    delay: 0.2,
                    duration: 0.2,
                }}
                sx={{ zIndex: 1 }}
                fontSize="medium"
            />

            <Box
                fontSize={12}
                component={motion.span}
                initial={{ color: 'rgba(0,0,0,0.6)' }}
                animate={{
                    color:
                        index === activeIndex ? '#1976D2' : 'rgba(0,0,0,0.6)',
                }}
            >
                {label}
            </Box>

            {index === activeIndex && <ActiveBubble />}
        </Stack>
    );
};

const ActiveBubble = () => {
    const sx = {
        position: 'absolute',
        top: -3,
        width: 1,
        height: '2rem',
        borderRadius: '1rem',
        backgroundColor: '#1976D2',
    };

    return <Box component={motion.div} layoutId="bubble" sx={sx} />;
};
