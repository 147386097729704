import {useLocation, Navigate} from "react-router-dom";
import {Outlet} from "react-router-dom";
import TokenService from "../services/token_service"

export default function RequirePermission({permission}) {
  let location = useLocation();

  if (!TokenService.hasPermission(permission)) {
    return <Navigate to="/ui" state={{ from: location }} />;
  }

  return <Outlet />;
}

