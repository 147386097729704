import { baseApi } from './index';
import { User } from '../models/User';

export const personApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getPerson: builder.query<User[], string>({
            query: (search) => ({
                url: 'persons',
                params: {
                    search,
                },
            }),
        }),
    }),
});

export const { useLazyGetPersonQuery } = personApi;
