import { usePrevious } from './usePrevious';
import { useEffect } from 'react';

export function useFormReset(value: any, reset: () => void) {
    const prevValue = usePrevious(value);

    useEffect(() => {
        if (!value && value !== prevValue) {
            reset();
        }
    });
}
