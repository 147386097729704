import React from 'react';
import { styled, Dialog, CircularProgress } from '@mui/material';

interface IProps {

}

const Container = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '10000',
    backgroundColor: 'gray',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 !important',
    opacity: .5,
}));

export const Loader: React.FC<IProps> = (props) => {
    const {

    } = props;

    return (
            <Dialog
                //fullScreen
                open={true}
                //onClose={onClose}
                //TransitionComponent={SlideUpTransition}
            >
                <div style={{
                    width: '100px',
                    height: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                }}>
                    <CircularProgress />
                </div>
            </Dialog>
    )
}