import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
} from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import BuildIcon from '@mui/icons-material/Build';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {Link} from 'react-admin';
import {Route, Routes, useNavigate} from 'react-router-dom';
import React from 'react';
import {FinesIndex} from './fines';
import {PassesIndex} from './passes';

const iconSx = {
  mb: 1,
  ml: 0.5,
  mr: 0.5,
};

const btnSx = {
  maxWidth: '20%',
  minWidth: '20%',
};

export const MobileRoot = (props) => {
  const navigate = useNavigate();

  return (
      <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            overflowY: 'scroll',
          }}
      >
        <Box flexGrow={1} sx={{width: '100%', overflowX: 'scroll'}}>
          <Routes>
            <Route path="fines" element={<FinesIndex/>}/>
            <Route path="passes" element={<PassesIndex/>}/>
          </Routes>
        </Box>
        <Box
            sx={{overflowX: 'hidden', width: '100%', flexShrink: 0}}
        >
          <BottomNavigation
              sx={{width: '100%'}}
              showLabels={true}
              onChange={(event, newValue) => {
              }}
          >
            <BottomNavigationAction
                sx={btnSx}
                label="Обращения"
                icon={<ReceiptIcon sx={iconSx}/>}
                onClick={e => {
                  navigate('fines');
                }}
            />
            <BottomNavigationAction
                sx={btnSx}
                label="Визитки"
                icon={
                  <PermContactCalendarIcon sx={iconSx}/>
                }
                onClick={e => {
                  navigate('passes');
                }}
            />
            <BottomNavigationAction
                sx={btnSx}
                label="SOS"
                icon={<LocalHospitalIcon sx={iconSx}/>}
            />
            <BottomNavigationAction
                sx={btnSx}
                label="Район"
                icon={<BuildIcon sx={iconSx}/>}
            />
            <BottomNavigationAction
                sx={btnSx}
                label="Больше"
                icon={<MoreHorizIcon sx={iconSx}/>}
            />
          </BottomNavigation>
        </Box>
      </Box>
  );
};