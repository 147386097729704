import {
    Autocomplete,
    Box,
    Button,
    Collapse,
    Paper,
    Stack,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import {
    Construction,
    ElectricalServices,
    ExpandMore,
    FormatColorReset,
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import React, { FC, useState } from 'react';
import { useLocation } from '../../../hooks/useLocation';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useFormReset } from '../../../hooks/useFormReset';
import { Location } from '../../../models/Location';
import { requiredRule } from '../../../models/Form';

export const Lyon = () => (
    <Stack spacing={2} m={2}>
        <LyonItem Icon={FormatColorReset} label="Сантехник" />
        <LyonItem Icon={ElectricalServices} label="Электрик" />
        <LyonItem Icon={Construction} label="Монтажник" />
    </Stack>
);

interface LyonItemProps {
    Icon: typeof SvgIcon;
    label: string;
}

interface LyonForm {
    location: Location;
    comment: string;
}

const LyonItem: FC<LyonItemProps> = ({ Icon, label }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen((isOpen) => !isOpen);

    const { control, reset, handleSubmit } = useForm<LyonForm>();
    const { area, line, place } = useLocation();
    const value = useWatch({
        control,
        name: 'location',
    });
    useFormReset(value, () => {
        area.setArea(null);
        area.setSearchArea('');
    });
    const submit = (data: LyonForm) => {
        console.log(data);
        reset();
        setIsOpen(false);
    };

    return (
        <Paper elevation={2}>
            <Stack m={2}>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    onClick={toggleOpen}
                >
                    <Icon />
                    <Typography variant="h6">{label}</Typography>
                    <Box flex={1}></Box>
                    <ExpandMore
                        initial={{
                            rotate: '0deg',
                        }}
                        animate={{
                            rotate: isOpen ? '180deg' : '0deg',
                        }}
                        component={motion.svg}
                        sx={{ color: '#1976D2' }}
                    />
                </Stack>
                <Collapse in={isOpen}>
                    <form onSubmit={handleSubmit(submit)}>
                        <Stack spacing={2} mt={2}>
                            <Stack spacing={1}>
                                <Autocomplete
                                    fullWidth
                                    size="small"
                                    id="area"
                                    options={area.areaSuggest ?? []}
                                    loadingText="Загрузка..."
                                    noOptionsText="Ничего не найдено"
                                    getOptionLabel={(option) =>
                                        `${option.building} ${option.floor}`
                                    }
                                    onChange={(e, a) => {
                                        area.setArea(a);
                                    }}
                                    isOptionEqualToValue={(o, v) =>
                                        o.building === v.building &&
                                        o.floor === v.floor
                                    }
                                    onInputChange={(e, v) => {
                                        area.setSearchArea(v);
                                    }}
                                    value={area.area}
                                    inputValue={area.searchArea}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Здание,этаж*"
                                        />
                                    )}
                                />
                                <Stack direction="row" spacing={1}>
                                    <Autocomplete
                                        fullWidth
                                        size="small"
                                        id="line"
                                        options={line.linesSuggest ?? []}
                                        loadingText="Загрузка..."
                                        noOptionsText="Ничего не найдено"
                                        getOptionLabel={(option) => option}
                                        onChange={(e, p) => {
                                            line.setLine(p);
                                        }}
                                        isOptionEqualToValue={(o, v) => o === v}
                                        onInputChange={(e, v) => {
                                            line.setSearchLine(v);
                                        }}
                                        value={line.line}
                                        inputValue={line.searchLine}
                                        disabled={!area.area}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Линия*"
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="location"
                                        control={control}
                                        rules={requiredRule}
                                        render={({
                                            field: { onChange, ...field },
                                            formState: { errors },
                                        }) => {
                                            const errorMessage = (
                                                errors.location?.message ?? ''
                                            ).toString();
                                            return (
                                                <Autocomplete
                                                    fullWidth
                                                    size="small"
                                                    id="location"
                                                    options={
                                                        place.placeSuggest ?? []
                                                    }
                                                    loadingText="Загрузка..."
                                                    noOptionsText="Ничего не найдено"
                                                    getOptionLabel={(option) =>
                                                        option.place_number
                                                    }
                                                    onChange={(e, p) => {
                                                        onChange(p);
                                                        place.setPlace(p);
                                                    }}
                                                    isOptionEqualToValue={(
                                                        o,
                                                        v
                                                    ) => o.id === v.id}
                                                    onInputChange={(e, v) => {
                                                        place.setSearchPlace(v);
                                                    }}
                                                    value={place.place}
                                                    inputValue={
                                                        place.searchPlace
                                                    }
                                                    disabled={!line.line}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            {...field}
                                                            label="Павильон*"
                                                            error={
                                                                !!errors.location
                                                            }
                                                            helperText={
                                                                errorMessage
                                                            }
                                                        />
                                                    )}
                                                />
                                            );
                                        }}
                                    />
                                </Stack>
                            </Stack>

                            <Controller
                                name="comment"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        id="comment"
                                        label="Комментарий"
                                        minRows={3}
                                        fullWidth
                                        multiline
                                        {...field}
                                    />
                                )}
                            />

                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                            >
                                Отправить
                            </Button>
                        </Stack>
                    </form>
                </Collapse>
            </Stack>
        </Paper>
    );
};
