import { baseApi } from './index';
import { Auth, AuthDto } from '../models/Auth';

export const authApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        auth: build.mutation<Auth, AuthDto>({
            query: (body) => ({
                url: 'login',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useAuthMutation } = authApi;
