import {useEffect, useState} from 'react'
import instance from "../services/api";

export default function FineTypesAPI() {
  const [fineTypes, setFineTypes] = useState([])
  const [callback, setCallback] = useState(false)

  useEffect(() => {
    const getFineTypes = async () => {
      const response = await instance.get(`/fine_types`)

      const fineTypes = response.data
      fineTypes.sort((a, b) => {
        if (a.code === 0) {
          return 1
        } else if (b.code === 0) {
          return -1
        } else if (a.code === b.code) {
          if (a.name > b.name) {
            return 1
          }
          return -1
        }

        return a.code - b.code
      })
      fineTypes.forEach(e => {
        if (!e.code) {
          e.code = 0
        }
      })
      setFineTypes(fineTypes)
    }
    getFineTypes()
  }, [callback])
  return {
    fineTypes: [fineTypes, setFineTypes],
    callback: [callback, setCallback],
  }
}