import { type } from "os";

export enum Permissions {
    CreateFine = 'create_fine',
    Admin = 'admin',
    FineChangeCode = 'change_fine_code',
    FineChangeType = 'change_type',
    FineChangeStatus = 'change_status',
    FineChangeLocation = 'change_location',
    FineStatusRepaid = 'fine_status_repaid',
    FineStatusPool = 'fine_status_pool',
    FineStatusNonPaid = 'fine_status_non_paid',
    FineStatusCollection = 'fine_status_collection',
    FineStatusCancelled = 'fine_status_cancelled',
    CreateFineCollection = 'create_fine_collection',
    CompleteFineCollection = 'complete_fine_collection',
    ExportExcel = 'export_excel',
    CreatePass = 'create_pass',
    Test = 'test',
}

export enum Roles {
    Unknown = 'unknown',
    Author = 'author',
    Administrator = 'administrator',
    Cashier = 'cashier',
}

export interface Auth {
    access_token: string;
    id: number;
    name: string;
    permissions: Permissions[];
    refresh_token: string;
    role: Roles;
    token_type: string;
}

export interface AuthDto {
    login: string;
    password: string;
    token_type: TTokenType;
}

export type TTokenType = 'mobile' | 'admin';

interface RefreshTokenResponse {
    refresh_token: string;
    access_token: string;
}

export function validateRefreshToken(
    data: unknown
): data is RefreshTokenResponse {
    if (!data || typeof data !== 'object') {
        return false;
    }

    if (!hasRefreshTokenKey(data) || !hasTokenKey(data)) {
        return false;
    }

    return (
        typeof data.refresh_token === 'string' &&
        typeof data.access_token === 'string'
    );
}

function hasRefreshTokenKey(data: {}): data is {
    [P in keyof Pick<RefreshTokenResponse, 'refresh_token'>]: unknown;
} {
    return 'refresh_token' in data;
}

function hasTokenKey(data: {}): data is {
    [P in keyof Pick<RefreshTokenResponse, 'access_token'>]: unknown;
} {
    return 'access_token' in data;
}
