import {DataGrid, ruRU} from "@mui/x-data-grid";
import {useContext, useEffect, useState} from "react";
import FinesService from "../services/fines_service"
import {DownloadFile, FormatStatus, StatusCancelled, StatusCollection, Statuses, StatusRepaid} from "../util";
import {useNavigate} from "react-router-dom";
import {FormControl, FormHelperText, MenuItem, Select, Stack, TextField, Tooltip} from "@mui/material";
import FineStats from "../components/legacy/fine_stats";
import {GlobalState} from "../GlobalState";
import ChooseLine from "../components/legacy/choose_line";
import ChoosePlace from "../components/legacy/choose_place";
import ChooseArea from "../components/legacy/choose_area";
import {DatePicker} from "@mui/x-date-pickers";
import ChooseUser from "../components/legacy/choose_user";
import ChooseFineReason from "../components/legacy/choose_fine_reason";
import ChoosePerson from "../components/legacy/choose_person";
import {withStyles} from "@mui/styles";

const TooltipWithBiggerFontSize = withStyles({
  tooltip: {
    fontSize: 14
  }
})(Tooltip)

const columns = [
  {
    field: 'id',
    headerName: 'Номер',
    width: 70,
    align: "right",
    headerAlign: "center",
  },
  {
    field: 'create_date',
    headerName: 'Дата',
    width: 100,
    align: "center",
    headerAlign: "center"
  },
  {
    field: 'create_time',
    headerName: 'Время',
    width: 80,
    align: "center",
    headerAlign: "center"
  },
  {
    field: 'location_area',
    headerName: 'Здание, этаж',
    width: 130,
    valueGetter: (params) => `${params.row.location.building} ${params.row.location.floor}`,
  },
  {
    field: 'location_line',
    headerName: 'Линия',
    width: 110,
    valueGetter: (params) => `${params.row.location.line}`,
  },
  {
    field: 'location_place_number',
    headerName: 'Павильон',
    width: 150,
    valueGetter: (params) => `${params.row.location.place_number}`,
  },
  {
    field: 'fine_reason',
    headerName: 'Причина',
    width: 500,
    align: "left",
    headerAlign: "center",
    valueGetter: (params) => `${params.row.fine_reason.name}`,
    renderCell: (params) => (
      <TooltipWithBiggerFontSize title={params.row.fine_reason.name}>
        <span className="table-cell-trucate">{params.row.fine_reason.name}</span>
      </TooltipWithBiggerFontSize>
    ),
  },
  {
    field: 'status',
    headerName: 'Статус',
    width: 120,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => FormatStatus(params.row.status),
  },
  {
    field: 'code',
    headerName: 'Код',
    width: 100,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => params.row.code ? params.row.code : 0,
  },
  {
    field: 'author',
    headerName: 'Автор',
    width: 200,
    valueGetter: (params) => `${params.row.creator_user.name}`,
  },
  {
    field: 'coauthor',
    headerName: 'Соавтор',
    width: 200,
    valueGetter: (params) => `${params.row.coauthor_user ? params.row.coauthor_user.name : ""}`,
  },
  {
    field: 'last_user',
    headerName: 'Журнал',
    width: 200,
    valueGetter: (params) => `${params.row.last_user.name}`,
  }
];


export default function AdminFinesList() {
  const state = useContext(GlobalState)
  const [page, setPage] = useState(0)
  const [rowCount, setRowCount] = useState(0)
  const [rows, setRows] = useState([])
  const [dataLoading, setDataLoading] = useState(false)
  const [statsLoading, setStatsLoading] = useState(false)
  const [stats, setStats] = useState(null)
  const [search, setSearch] = useState({
    status: "",
    fine_type_id: "",
    fine_reason: null,
    creator_user: null,
    coauthor_user: null,
    location_area: null,
    location_line: "",
    location: null,
    date_from: null,
    date_to: null,
  })
  const navigate = useNavigate()

  const getFinesParams = () => {
    return {
      ...search,
      page: page + 1,
      fine_reason_id: search.fine_reason ? search.fine_reason.id : "",
      location_building: search.location_area ? search.location_area.building : "",
      location_floor: search.location_area ? search.location_area.floor : "",
      location_line: search.location_line ? search.location_line : "",
      location_id: search.location ? search.location.id : "",
      date_from: search.date_from ? search.date_from.format("L") : "",
      date_to: search.date_to ? search.date_to.format("L") : "",
      creator_user_id: search.creator_user ? search.creator_user.id : "",
      coauthor_user_id: search.coauthor_user ? search.coauthor_user.id : "",
    }
  }

  const refreshData = async () => {
    setDataLoading(true)

    try {
      const finesResponse = await FinesService.getFines(getFinesParams())
      setRows(finesResponse.items)
      setRowCount(finesResponse.total_count)
      setStats(await FinesService.getStats())
    } catch (e) {
    }
    setDataLoading(false)
  }

  const refreshStats = async () => {
    setStatsLoading(true)

    try {
      setStats(await FinesService.getStats())
    } catch (e) {
    }
    setStatsLoading(false)
  }

  const refreshAll = async () => {
    await Promise.all([refreshData(), refreshStats()])
  }

  const exportData = async () => {
    setStatsLoading(true)
    try {
      const data = await FinesService.exportFines(getFinesParams())
      DownloadFile(data, `обращения-${(new Date()).toLocaleString("ru-RU")}.xlsx`)
    } catch (e) {
    } finally {
      setStatsLoading(false)
    }
  }

  const navigateToCollection = () => {
    navigate("/ui/admin/fines/collection")
  }

  const navigateToCompleteCollection = () => {
    navigate("/ui/admin/fines/complete_collection")
  }

  useEffect(() => {
    refreshData().catch(console.error)
  }, [page, search])

  useEffect(() => {
    refreshStats().catch(console.error)
  }, [])

  // auto refresh every 10 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      refreshAll().catch(console.error)
    }, 1000 * 60 * 10);

    return () => {
      clearInterval(interval);
    };
  }, []); // has no dependency - this will be called on-component-mount

  const filterStyle = {minWidth: 120, maxWidth: 220, mr: 1, flexGrow: 1}

  return (
    <Stack direction="column" sx={{
      '& .data-grid_regime-violation': {
        bgcolor: "#F0E68C"
      },
      '& .data-grid_status-collection': {
        bgcolor: "#00BFFF"
      },
      '& .data-grid_status-repaid': {
        bgcolor: "#98FB98"
      },
      '& .data-grid_status-cancelled': {
        bgcolor: "#FA8072"
      }
    }}>
      <Stack direction="row" sx={{mb: 1, width: "100%"}}>
        <Stack direction="column" flexGrow={1} flexWrap="wrap">
          <Stack direction="row" sx={{flexShrink: 1}}>
            <FormControl sx={filterStyle}>
              <FormHelperText>Причина</FormHelperText>
              <ChooseFineReason
                value={search.fine_reason}
                size="small"
                onChange={e => setSearch({...search, fine_reason: e})}
              />
            </FormControl>

            <FormControl sx={filterStyle}>
              <FormHelperText>Статус</FormHelperText>
              <Select
                value={search.status}
                size="small"
                onChange={(e) => setSearch({...search, status: e.target.value})}
              >
                <MenuItem value="">
                  <em>---</em>
                </MenuItem>
                {Statuses.map((el, idx) => (
                  <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={filterStyle}>
              <FormHelperText>Здание, этаж</FormHelperText>
              <ChooseArea
                fullWidth
                onChooseArea={area => setSearch({
                  ...search,
                  location_area: area,
                  location_line: "",
                  location: null,
                })}
                area={search.location_area}
                size="small"
              />
            </FormControl>

            <FormControl sx={filterStyle}>
              <FormHelperText>Линия</FormHelperText>
              <ChooseLine
                fullWidth
                onChooseLine={line => setSearch({
                  ...search,
                  location_line: line,
                  location: null,
                })}
                area={search.location_area}
                line={search.location_line}
                size="small"
              />
            </FormControl>

            <FormControl sx={filterStyle}>
              <FormHelperText>Павильон</FormHelperText>
              <ChoosePlace
                fullWidth
                onChooseLocation={location => setSearch({
                  ...search,
                  location: location,
                })}
                area={search.location_area}
                line={search.location_line}
                location={search.location}
                size="small"
              />
            </FormControl>
          </Stack>

          <Stack direction="row" sx={{flexShrink: 1}}>
            <FormControl sx={filterStyle}>
              <FormHelperText>Дата, с</FormHelperText>
              <DatePicker
                mask="__.__.____"
                renderInput={(props) => <TextField size="small" {...props} />}
                label=""
                value={search.date_from}
                onChange={(newValue) => {
                  setSearch({
                    ...search,
                    date_from: newValue
                  })
                }}
              />
            </FormControl>

            <FormControl sx={filterStyle}>
              <FormHelperText>по</FormHelperText>
              <DatePicker
                mask="__.__.____"
                renderInput={(props) => <TextField size="small" {...props} />}
                label=""
                value={search.date_to}
                onChange={(newValue) => {
                  setSearch({
                    ...search,
                    date_to: newValue
                  })
                }}
              />
            </FormControl>

            <FormControl sx={filterStyle}>
              <FormHelperText>Автор</FormHelperText>
              <ChooseUser
                user={search.creator_user}
                size="small"
                onChooseUser={user => setSearch({...search, creator_user: user})}
              />
            </FormControl>

            <FormControl sx={filterStyle}>
              <FormHelperText>Соавтор</FormHelperText>
              <ChoosePerson
                value={search.coauthor_user}
                size="small"
                onChange={user => setSearch({...search, coauthor_user: user})}
              />
            </FormControl>

          </Stack>

        </Stack>

        <Stack direction="column" sx={{height: "100%"}} justifyContent="end">
          <FineStats
            stats={stats}
            onRefresh={refreshAll}
            onExport={exportData}
            onCollection={navigateToCollection}
            onCompleteCollection={navigateToCompleteCollection}
            disabled={statsLoading}
          />
        </Stack>
      </Stack>
      <DataGrid
        density="compact"
        autoHeight
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        loading={dataLoading}
        pagination
        paginationMode="server"
        pageSize={100}
        page={page}
        rowsPerPageOptions={[10,25,50,100,200]}
        rowCount={rowCount ? rowCount : 0}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        onPageChange={(p) => {
          setPage(p)
        }}
        onRowClick={(params) => {
          window.open(`${window.location.origin}/ui/admin/fines/${params.row.id}`)
        }}
        getRowClassName={(params) => {
          if (params.row.code === 0) {
            return `data-grid_regime-violation`
          } else if (params.row.status === StatusCollection) {
            return 'data-grid_status-collection'
          } else if (params.row.status === StatusRepaid) {
            return `data-grid_status-repaid`
          } else if (params.row.status === StatusCancelled) {
            return `data-grid_status-cancelled`
          } else if (params.row.fine_type.name === 'Прочее') {
            return `data-grid_other`
          }
          return ''
        }}
      />
    </Stack>
  )
}