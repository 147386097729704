import { Backdrop, Box, Stack, Typography } from '@mui/material';
import { motion, PanInfo, useAnimation, useDragControls } from 'framer-motion';
import { FC, useEffect } from 'react';
import { usePrevious } from '../../../hooks/usePrevious';
import {
    AccountCircle,
    CreditCard,
    ManageAccounts,
    Store,
    Storefront,
    SvgIconComponent,
    Warehouse,
} from '@mui/icons-material';

interface CreateCardDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    openCreating: () => void;
}

export const CreateCardDialog: FC<CreateCardDialogProps> = ({
    isOpen,
    onOpen,
    onClose,
    openCreating,
}) => {
    const controls = useAnimation();
    const prevIsOpen = usePrevious(isOpen);
    const dragControls = useDragControls();

    function onDragEnd(_: any, info: PanInfo) {
        const shouldClose =
            info.velocity.y > 20 || (info.velocity.y >= 0 && info.point.y > 45);
        if (shouldClose) {
            onClose();
        } else {
            onOpen();
        }
    }

    useEffect(() => {
        if (prevIsOpen && !isOpen) {
            controls.start('hidden');
        } else if (!prevIsOpen && isOpen) {
            controls.start('visible');
        }
    }, [controls, isOpen, prevIsOpen]);

    return (
        <Backdrop
            open={isOpen}
            sx={{ zIndex: 2, touchAction: 'pan-x' }}
            onPointerDown={(e) => dragControls.start(e)}
        >
            <Box
                dragControls={dragControls}
                component={motion.div}
                drag="y"
                onDragEnd={onDragEnd}
                dragConstraints={{ top: 0, bottom: 100 }}
                dragElastic={0.2}
                initial="hidden"
                animate={controls}
                transition={{
                    type: 'spring',
                    damping: 40,
                    stiffness: 400,
                }}
                variants={{
                    visible: { y: 0 },
                    hidden: { y: '70%' },
                }}
                sx={{
                    position: 'fixed',
                    top: '87%',
                    left: 0,
                    right: 0,
                    height: '100vh',
                    backgroundColor: '#fff',
                    zIndex: 5,
                    borderRadius: 2,
                }}
            >
                <Stack
                    spacing={2}
                    px={2}
                    sx={{ position: 'relative', paddingTop: '20px' }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '-20px',
                            left: '0',
                            right: '0',
                            height: '60px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                width: 40,
                                height: 6,
                                borderRadius: 6,
                                backgroundColor: '#ccc',
                                margin: '0 auto',
                            }}
                        ></Box>
                    </Box>
                    <Button
                        onClick={() => {
                            onClose();
                            openCreating();
                        }}
                        Icon={Store}
                        title="Торговая точка"
                        color="#32CD32"
                    />
                    {/*<Button Icon={Warehouse} title="Склад" color="#FF8C00" />*/}
                    {/*<Button*/}
                    {/*    Icon={ManageAccounts}*/}
                    {/*    title="Услуга"*/}
                    {/*    color="#00BFFF"*/}
                    {/*/>*/}
                    {/*<Button*/}
                    {/*    Icon={Storefront}*/}
                    {/*    title="Ночная витрина"*/}
                    {/*    color="#9370DB"*/}
                    {/*/>*/}
                    {/*<Button*/}
                    {/*    Icon={CreditCard}*/}
                    {/*    title="Магнитная карта"*/}
                    {/*    color="#808080"*/}
                    {/*/>*/}
                    {/*<Button*/}
                    {/*    Icon={AccountCircle}*/}
                    {/*    title="Посредник"*/}
                    {/*    color="#000"*/}
                    {/*/>*/}
                </Stack>
            </Box>
        </Backdrop>
    );
};

interface ButtonProps {
    title: string;
    Icon: SvgIconComponent;
    color: string;
    onClick?: () => void;
}

const Button: FC<ButtonProps> = ({ color, Icon, title, onClick }) => {
    return (
        <Box
            onClick={onClick}
            sx={{
                height: 56,
                width: '100%',
                padding: 2,
                backgroundColor: color === '#000' ? '#fff' : color,
                borderRadius: '8px',
                border: color === '#000' ? '1px solid #000' : 'none',
            }}
        >
            <Stack direction="row" alignItems="center" spacing={2}>
                <Icon sx={{ color: color === '#000' ? '#000' : '#fff' }} />
                <Typography
                    sx={{ color: color === '#000' ? '#000' : '#fff' }}
                    variant="body1"
                >
                    {title}
                </Typography>
            </Stack>
        </Box>
    );
};
