import {BooleanField, Datagrid, EditButton, List, ShowButton, TextField} from "react-admin";
import React from "react";


export const UserList = () => (
  <List>
    <Datagrid>
      <TextField source="id" label="ID"/>
      <TextField source="name" label="Имя"/>
      <TextField source="login" label="Логин"/>
      <TextField source="import_id" label="ID Бюро"/>
    </Datagrid>
  </List>
);