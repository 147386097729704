export const PermissionCreateFine = 'create_fine'
export const PermissionCreatePass = 'create_pass'
export const PermissionAdmin = 'admin'
export const PermissionFineChangeCode = 'change_fine_code'
export const PermissionFineChangeType = 'change_type'
export const PermissionFineChangeStatus = 'change_status'
export const PermissionFineChangeLocation = 'change_location'
export const PermissionFineStatusRepaid = 'fine_status_repaid'
export const PermissionFineStatusPool = 'fine_status_pool'
export const PermissionFineStatusNonPaid = 'fine_status_non_paid'
export const PermissionFineStatusCollection = 'fine_status_collection'
export const PermissionFineStatusCancelled = 'fine_status_cancelled'
export const PermissionCreateFineCollection = 'create_fine_collection'
export const PermissionCompleteFineCollection = 'complete_fine_collection'
export const PermissionTest = 'test'
export const PermissionExportExcel = 'export_excel'
export const TokenTypeAdmin = 'admin'
export const TokenTypeMobile = 'mobile'
export const TokenTypeAny = 'any'

class TokenService {
  getLocalRefreshToken() {
    const user = this.getUser()
    return user?.refresh_token
  }

  getLocalAccessToken() {
    const user = this.getUser()
    return user?.access_token || localStorage.getItem('admin_token')
  }

  updateTokens(accessToken, refreshToken) {
    let user = this.getUser()
    user.access_token = accessToken
    user.refresh_token = refreshToken
    localStorage.setItem('user', JSON.stringify(user))
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'))
  }

  setUser(user) {
    console.log(JSON.stringify(user))
    localStorage.setItem('user', JSON.stringify(user))
  }

  removeUser() {
    localStorage.removeItem('user')
  }

  hasPermission(permission) {
    if (permission === PermissionCreateFine) {
      return true
    }

    const user = this.getUser()
    if (!user || !user.permissions) {
      return false
    }

    return user.permissions.find(p => p === permission) !== undefined
  }
}

export default new TokenService()