import React, { useState } from 'react';
import {
    DateField,
    FunctionField,
    ReferenceField,
    SelectField,
    Show,
    SimpleShowLayout,
    TextField,
    useGetManyReference,
    useGetOne,
    useNotify,
    useRecordContext,
    WrapperField,
} from 'react-admin';
import {Box, Button, Chip, Divider, Stack, Typography,} from '@mui/material';
import {PassStatuses} from 'src/util';
import moment from 'moment-timezone';
import {ActionButtons} from './components/ActionButtons';
import {FlagsField} from "src/components/legacy/admin/pass/components/FlagsField";
import {PartnerName} from 'src/components/legacy/admin/components/partner_name';
import PassService from 'src/services/pass_service';

const StatusField: React.FC = (props) => {
    const record = useRecordContext();

    return (
        record.to_be_agreed
            ? <Box bgcolor="error.main">
                <Typography variant="h4" color="white" align="center">
                    На согласовании
                </Typography>
            </Box>
            : null
    );
};

const OpenCloseField: React.FC = (props) => {
    const record = useRecordContext();

    if (record && record['open_close']) {
        return (
            <Chip
                variant="outlined" color="success"
                size="small" sx={{mr: 1}} label="Допущен"
            />
        );
    }
    return (
        <Chip
            variant="outlined" color="error"
            size="small" sx={{mr: 1}} label="Не допущен"
        />
    );
};


const HistoryItem: React.FC<any> = ({item}) => {
    const {data: user} = useGetOne('users', {id: item.user_id});

    return (
        <Stack direction="column" sx={{mb: 2}}>
            <Typography
                variant={'body2'}
                fontWeight={'bold'}
                style={{whiteSpace: 'pre-wrap'}}
            >{item.info}</Typography>
            <Stack direction="row" justifyContent={'space-between'}>
                <Typography variant={'body2'} color={'grey.600'} sx={{mb: 0.5}}>
                    {user && `${user.name} (${user.login})`}
                </Typography>
                <Typography variant={'body2'} color={'grey.600'}>
                    {user &&
                        `${moment(item.created_at).format('DD.MM.YYYY, hh:mm:ss')}`}
                </Typography>
            </Stack>
            <Divider sx={{mt: 2}}/>
        </Stack>
    );
};

const HistoryBlock: React.FC<any> = ({record}) => {
    const {data: historyItems} = useGetManyReference(
        'pass_history',
        {
            target: 'pass_id',
            id: record.id,
            pagination: {page: 1, perPage: 100},
            sort: {field: 'created_at', order: 'DESC'},
        });

    return (
        <Box>
            {historyItems && historyItems.map((el, i) => {
                return (
                    <HistoryItem key={i} item={el}/>
                );
            })}
        </Box>
    );
};

const HistoryWrapper: React.FC = () => {
    const record = useRecordContext();

    return (
        <Stack direction="column" sx={{ml: 2, mt: 2, mb: 2}}>
            <Typography variant="subtitle1" color="grey.600" sx={{mb: 2}}>
                История изменений
            </Typography>
            {record && <HistoryBlock record={record}/>}
        </Stack>
    );
};

const RightSide: React.FC = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const [ isLoading, setIsLoading ] = useState(false)

    const handleCancel = async () => {
        setIsLoading(true)
        try {
            await PassService.passCancel({
                id: record.id
            });
            notify('Успешно', {type: 'success'});
        } catch (error: any) {
            notify(error.message, {type: 'error'});
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <HistoryWrapper/>
    )
}

export const PassShow: React.FC = (props) => {
    return (
        <Show aside={<RightSide />}>
            <SimpleShowLayout>
                <StatusField/>
                <TextField
                    source="id"
                    variant="subtitle1"
                    label="ID"
                />
                <TextField
                    source="external_id"
                    variant="subtitle1"
                    label="ID из бюро"
                />
                <DateField
                    source="created_at"
                    variant="subtitle1"
                    label="Создан"
                    showTime
                />
                <DateField
                    source="valid_until"
                    variant="subtitle1"
                    label="До"
                    showTime
                />
                <ReferenceField
                    label="Автор"
                    reference="users"
                    source="creator_user_id"
                    link={false}
                >
                    <FunctionField
                        render={(record: any) => <Typography>{`${record.name} (${record.login})`}</Typography>}
                    />
                </ReferenceField>
                <WrapperField label="На кого">
                    <PartnerName/>
                </WrapperField>
                <ReferenceField
                    label="Торговая точка"
                    reference="locations"
                    source="location_id"
                    link={false}
                >
                    <FunctionField
                        render={(record: any) => <Typography>{record.name}</Typography>}
                    />
                </ReferenceField>
                <SelectField
                    source="status"
                    label="Статус"
                    variant="subtitle1"
                    choices={PassStatuses}
                />
                <ReferenceField
                    label="Тип партнера"
                    reference="partner_types"
                    source="partner_type_id"
                    link={false}
                >
                    <FunctionField
                        render={(record: any) => <Typography>{record.name}</Typography>}
                    />
                </ReferenceField>
                <ReferenceField
                    label="Должность"
                    reference="job_titles"
                    source="jot_title_id"
                    link={false}
                >
                    <FunctionField
                        render={(record: any) => <Typography>{record.name}</Typography>}
                    />
                </ReferenceField>
                <OpenCloseField/>
                <FlagsField/>
                <TextField
                    source="comment"
                    variant="subtitle1"
                    label="Комментарий"
                />
                <ActionButtons/>
            </SimpleShowLayout>
        </Show>
    );
};
