import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from '../api/auth';
import jwt_decode from 'jwt-decode';
import { Auth } from '../models/Auth';

export const TOKEN_LOCALSTORAGE_KEY = 'token';
export const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'refresh-token';

type AuthState = {
    token: string | null;
    refreshToken: string | null;
    userId?: number;
};

type JwtPayload = Omit<
    Auth,
    'refresh_token' | 'token_type' | 'access_token'
> & { exp: number };

type RefreshPayload = {
    refresh_token: string;
    access_token: string;
};

function getInitialState(): AuthState {
    const token = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
    const state: AuthState = { token, refreshToken };

    if (token) {
        const jwtPayload = jwt_decode<JwtPayload>(token);

        state.userId = jwtPayload.id;
    }

    return state;
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: getInitialState(),
    reducers: {
        logout() {
            return { token: null, refreshToken: null };
        },
        refresh(state, action: PayloadAction<RefreshPayload>) {
            state.refreshToken = action.payload.refresh_token;
            state.token = action.payload.access_token;
        },
    },
    extraReducers: (builder) => {
        return builder.addMatcher(
            authApi.endpoints.auth.matchFulfilled,
            (state, { payload }) => {
                state.token = payload.access_token;
                state.refreshToken = payload.refresh_token;
                state.userId = payload.id;
            }
        );
    },
});

export const { logout, refresh } = authSlice.actions;
