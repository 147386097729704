import { SlideUpTransition } from '../common/SlideUpTransition';
import React, { FC, useEffect, useState } from 'react';
import {
    Backdrop,
    Box,
    Button,
    Dialog,
    Divider,
    Stack,
    Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useDialog } from '../../../hooks/useDialog';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { closeAppealsDialog } from '../../../store/appeals/appealsDialog';
import { Fine, FineHistoryItem } from '../../../models/Fine';
import { useGetFineHistoryQuery } from '../../../api/fine';
import { Images } from './Images';

export const ViewerDialog: FC = () => {
    const fine = useSelector<RootState, Fine | null>(
        (state) => state.appealsDialog
    );
    const open = fine !== null;
    const dispatch = useDispatch();
    const onClose = () => dispatch(closeAppealsDialog());
    const { data: history } = useGetFineHistoryQuery(fine?.id ?? 0, {
        skip: !open,
        refetchOnMountOrArgChange: true,
    });

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={SlideUpTransition}
        >
            {fine && (
                <>
                    <Stack
                        direction="column"
                        p={2}
                        spacing={3}
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: '#fff',
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={1}
                        >
                            <Button
                                onClick={onClose}
                                variant="contained"
                                sx={{
                                    minWidth: 'auto',
                                    width: '3rem',
                                    height: '48px',
                                }}
                            >
                                <ArrowBack />
                            </Button>
                            <Stack flex={1}>
                                <Box
                                    component="span"
                                    sx={{ color: 'rgba(0,0,0,0.26)' }}
                                >
                                    {`#${fine.id}`}
                                </Box>
                                <Box
                                    component="span"
                                    sx={{ color: 'rgba(0,0,0,0.55)' }}
                                >
                                    {fine.create_date}
                                </Box>
                            </Stack>
                            <Box
                                textAlign="right"
                                component="span"
                                flex={2}
                                sx={{ color: 'rgba(0,0,0,0.55)' }}
                            >
                                {fine.location.name}
                            </Box>
                        </Stack>
                    </Stack>

                    <Box sx={{ marginTop: '5rem', padding: '0 1rem' }}>
                        <Images
                            images={[fine.pass_photo, fine.violation_photo]}
                        />
                    </Box>

                    <ListItem title="Автор" value={fine.creator_user.name} />
                    {fine.coauthor_user && (
                        <ListItem
                            title="Соавтор"
                            value={fine.coauthor_user.name}
                        />
                    )}
                    <ListItem title="Причина" value={fine.fine_reason.name} />
                    {fine.description && (
                        <ListItem title="Описание" value={fine.description} />
                    )}

                    {history && <History history={history} />}
                </>
            )}
        </Dialog>
    );
};

interface ListItemProps {
    title: string;
    value: string;
}

const ListItem: FC<ListItemProps> = ({ title, value }) => {
    return (
        <Stack p={2} pt={0}>
            <Typography sx={{ color: 'rgba(0,0,0,0.38)' }} variant="subtitle2">
                {title}
            </Typography>
            <Typography variant="body2">{value}</Typography>
        </Stack>
    );
};

const HistoryItem: FC<FineHistoryItem> = (historyItem) => {
    const littleLine = { color: 'rgba(0,0,0,0.38)', fontSize: 12 };
    return (
        <Stack mt={2}>
            <Box component="pre" m={0} sx={{ whiteSpace: 'pre-wrap' }}>
                <Typography variant="subtitle2">{historyItem.info}</Typography>
            </Box>
            <Stack direction="row" justifyContent="space-between" mb={1}>
                <Typography variant="body2" sx={littleLine}>
                    {historyItem.user.name}
                </Typography>
                <Typography variant="body2" sx={littleLine}>
                    {historyItem.create_date} {historyItem.create_time}
                </Typography>
            </Stack>
            <Divider light />
        </Stack>
    );
};

interface HistoryProps {
    history: FineHistoryItem[];
}

const History: FC<HistoryProps> = ({ history }) => {
    return (
        <Stack p={2} pt={0}>
            <Typography sx={{ color: 'rgba(0,0,0,0.38)' }} variant="subtitle2">
                История изменений
            </Typography>

            {history.map((h, i) => (
                <HistoryItem key={i} {...h} />
            ))}
        </Stack>
    );
};
