import {
    Box,
    Button,
    Chip,
    Paper,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import { Language, PersonOff, WatchLater } from '@mui/icons-material';
import { IconTooltip } from './IconTooltip';
import { Logs } from './Logs';
import {
    calculateValidDays,
    mapOpenCloseToBgColor,
    mapOpenCloseToFgColor,
    mapOpenCloseToLabel,
    OptionTooltip,
    Pass,
    statusChipOptions,
} from '../../../models/Passes';
import { FC } from 'react';
import {FormatJobTitle, PassStatuses} from "src/util";

type PassLoading = {
    pass?: Pass;
    isLoading: true;
};

type PassSuccess = {
    pass: Pass;
    isLoading: false;
};

export const Card: FC<PassSuccess | PassLoading> = ({ pass, isLoading }) => {
    const LoadingTextSkeleton = (
        <Skeleton variant="text" sx={{ minWidth: '2rem' }} />
    );
    const { date, days } = calculateValidDays(pass?.valid_until);

    return (
        <Paper elevation={5} sx={{ overflow: 'hidden' }}>
            <Stack>
                <Box
                    sx={{
                        background: isLoading ? 'white' : '#32CD32',
                    }}
                >
                    <Stack direction="row" p={1}>
                        <Typography
                            variant="body2"
                            sx={{ color: isLoading ? 'black' : '#fff' }}
                            flex={1}
                        >
                            {isLoading ? LoadingTextSkeleton : `#${pass.id}`}
                        </Typography>

                        <Typography
                            variant="body2"
                            sx={{ color: isLoading ? 'black' : '#fff' }}
                            flex={1}
                            textAlign="right"
                        >
                            {isLoading
                                ? LoadingTextSkeleton
                                : `${pass.location.name}`}
                        </Typography>
                    </Stack>
                </Box>
                <Box p={1}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="baseline"
                    >
                        <Typography
                            variant="subtitle2"
                            flex={1}
                            sx={{
                                fontSize: '18px',
                                lineHeight: '120%',
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            {isLoading
                                ? LoadingTextSkeleton
                                : pass.partner.full_name}
                        </Typography>
                        <Stack
                            direction="row"
                            flex={1}
                            justifyContent="flex-end"
                            spacing={1}
                        >
                            {pass?.in_absentia && (
                                <IconTooltip
                                    title={OptionTooltip.inAbsentia}
                                    Icon={PersonOff}
                                />
                            )}
                            {pass?.to_be_agreed && (
                                <IconTooltip
                                    title={OptionTooltip.toBeAgreed}
                                    Icon={WatchLater}
                                />
                            )}
                            {pass?.online_store && (
                                <IconTooltip
                                    title={OptionTooltip.onlineStore}
                                    Icon={Language}
                                />
                            )}
                        </Stack>
                    </Stack>

                    <Stack
                        direction="row"
                        mt={1}
                        alignItems="baseline"
                        spacing={1}
                    >
                        {isLoading ? (
                            LoadingTextSkeleton
                        ) : (
                            <Chip
                                label={mapOpenCloseToLabel(pass.open_close)}
                                sx={{
                                    color: mapOpenCloseToFgColor(
                                        pass.open_close
                                    ),
                                    backgroundColor: mapOpenCloseToBgColor(
                                        pass.open_close
                                    ),
                                    fontWeight: 500,
                                }}
                                size="small"
                            />
                        )}
                        <Typography
                            variant="body2"
                            sx={{ color: 'rgba(0,0,0,0.58)' }}
                        >
                            {isLoading
                                ? LoadingTextSkeleton
                                : FormatJobTitle(pass)}
                        </Typography>

                        {pass?.status
                            && <Chip
                                label={PassStatuses.find(item => item.id === pass?.status)?.name}
                                sx={statusChipOptions(pass?.status)}
                                size="small"
                            />
                        }
                    </Stack>

                    <Stack
                        direction="row"
                        alignItems="baseline"
                        justifyContent="space-between"
                    >
                        <Stack direction="row" mt={2}>
                            <Typography
                                variant="body2"
                                sx={{ color: 'rgba(0,0,0,0.38)' }}
                            >
                                {isLoading ? LoadingTextSkeleton : date}&nbsp;
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: pass?.was_delayed
                                        ? '#1976D2'
                                        : 'rgba(0,0,0,0.38)',
                                }}
                            >
                                {isLoading ? LoadingTextSkeleton : days}
                            </Typography>
                        </Stack>
                        <Button
                            sx={{ display: 'none' }} // Пока нет медота. Скрыть
                            variant="text"
                            size="small"
                        >
                            Отсрочка
                        </Button>
                    </Stack>
                    {/*<Logs />*/}
                </Box>
            </Stack>
        </Paper>
    );
};
