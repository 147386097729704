import { useEffect, useState } from 'react';
import { FineReason } from '../models/Fine';
import { useLazyGetFineReasonsQuery } from '../api/fine';

export function useFineReasonSuggest() {
    const [searchReason, setSearchReason] = useState('');
    const [reason, setReason] = useState<FineReason | null>(null);
    const [triggerFineReasonSuggest, { data: reasonSuggest }] =
        useLazyGetFineReasonsQuery();

    useEffect(() => {
        if (searchReason.length === 0) {
            return;
        }

        triggerFineReasonSuggest(searchReason);
    }, [searchReason, triggerFineReasonSuggest]);

    return {
        searchReason,
        setSearchReason,
        reason,
        setReason,
        reasonSuggest,
    };
}
