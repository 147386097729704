import {useEffect, useState} from "react";
import FinesService from "../services/fines_service"
import LoadingWrapper from "../components/legacy/loading_screen";
import {Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {FormatError} from "../util";
import {useNavigate} from "react-router-dom";

export default function AdminFinesCollection() {
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  const [sending, setSending] = useState(false)
  const [sendingError, setSendingError] = useState(false)
  const [sendingErrorText, setSendingErrorText] = useState('')
  const navigate = useNavigate()

  const loadData = async () => {
    setLoading(true)

    try {
      setRows((await FinesService.getFinesForCollection()).items)
    } catch (e) {
      setLoadingError(true)
    } finally {
      setLoading(false)
    }
  }

  const sendCollect = async () => {
    setSending(true)

    try {
      const ids = rows.map((row) => row.id)
      await FinesService.collectFines(ids)
      setSendingError(false)
      setSending(false)
      navigate("/ui/admin/fines/")
    } catch (e) {
      setSending(false)
      setSendingError(true)
      if (e.response && e.response.data.code === 90002) {
        setSendingErrorText('Одно или несколько обращений изменило статус, обновите страницу')
      } else {
        setSendingErrorText(FormatError(e))
      }
    }
  }

  useEffect(() => {
    loadData().catch(console.error)
  }, [])

  const totalSum = rows.length > 0 ? rows.reduce((a, b) => a + b.code, 0) : 0

  return (
    <LoadingWrapper loading={loading} error={loadingError}>
      {rows.length === 0 &&
        <Typography variant="h6" align="center">Нет обращений для recalculation</Typography>
      }
      {rows.length > 0 &&
        <Stack direction="column" alignContent="center" alignItems="center">
          <Typography variant="h6" align="center">Обращения для recalculation</Typography>
          <Table size="small" sx={{maxWidth: "800px", mb: 2}}>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Дата</TableCell>
                <TableCell>Причина</TableCell>
                <TableCell>Локация</TableCell>
                <TableCell>Код</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell>{`${row.create_date} ${row.create_time}`}</TableCell>
                  <TableCell>{row.fine_reason.name}</TableCell>
                  <TableCell>{row.location.name}</TableCell>
                  <TableCell>{row.code}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Stack direction="row" alignItems="center">
            <Typography variant="body" align="center">Сумма кодов: {totalSum}</Typography>
            <LoadingButton
              size="small"
              sx={{ml: 2}}
              loading={sending}
              variant="contained"
              onClick={() => sendCollect()}>
              Recalculation
            </LoadingButton>
          </Stack>
          {sendingError &&
            <Typography
              textAlign="center"
              variant="body2"
              sx={{mt: 1, color: (theme) => theme.palette.error.main}}
              gutterBottom
            >{sendingErrorText}</Typography>}
        </Stack>
      }
    </LoadingWrapper>
  )
}