import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FineStatus } from '../../models/Fine';

export type AppealsStatusFilterState = {
    [key in FineStatus]: boolean;
};

export const appealsStatusFilterSlice = createSlice({
    name: 'appealsStatusFilterSlice',
    initialState: {} as AppealsStatusFilterState,
    reducers: {
        set(
            state,
            action: PayloadAction<{ status: FineStatus; state: boolean }>
        ) {
            state[action.payload.status] = action.payload.state;
        },
    },
});

export const { set: setAppealsStatusFilter } = appealsStatusFilterSlice.actions;
