import { baseApi } from './index';
import { Area, Line, Location } from '../models/Location';

type SearchLinesParams = {
    search: string;
    area: Area;
};

type SearchPlaceParams = SearchLinesParams & {
    line: Line;
};

export const locationApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAreas: builder.query<Area[], string>({
            query: (search) => ({
                url: 'locations/areas',
                params: {
                    search,
                },
            }),
        }),
        getLines: builder.query<string[], SearchLinesParams>({
            query: (args) => ({
                url: 'locations/lines',
                params: {
                    search: args.search,
                    building: args.area.building,
                    floor: args.area.floor,
                },
            }),
        }),
        getPlaces: builder.query<Location[], SearchPlaceParams>({
            query: (args) => ({
                url: 'locations/places',
                params: {
                    search: args.search,
                    building: args.area.building,
                    floor: args.area.floor,
                    line: args.line,
                },
            }),
        }),
        getLocationByNumber: builder.query<Location, {
            number: string;
        }>({
            query: ({number}) => ({
                url: `/locations/qr/${number}`,
            }),
        }),
    }),
});

export const {
    useLazyGetAreasQuery,
    useLazyGetLinesQuery,
    useLazyGetPlacesQuery,
    useLazyGetLocationByNumberQuery,
} = locationApi;
