import { useEffect, useState } from 'react';
import { useLazyGetPartnerQuery } from '../api/partner';
import { IPartner } from 'src/models/Partner';

export function usePartnerSuggest() {
    const [searchPartner, setSearchPartner] = useState('');
    const [partner, setPartner] = useState<IPartner | null>(null);
    const [triggerSearchPartner, { data: partnerSuggest }] =
        useLazyGetPartnerQuery();
    
    useEffect(() => {
        if (searchPartner.length === 0) {
            return;
        }

        triggerSearchPartner(searchPartner);
    }, [searchPartner, triggerSearchPartner]);

    return {
        searchPartner,
        setSearchPartner,
        partner,
        setPartner,
        partnerSuggest,
        triggerSearchPartner
    };
}
