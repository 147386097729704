import {Autocomplete, TextField, Typography} from "@mui/material";
import {useEffect, useMemo, useRef, useState} from "react";
import throttle from 'lodash/throttle';
import {LocationsService} from "../../services/locations_service";

const autocompleteService = {current: null};

export default function ChoosePlace(
  {
    onChooseLocation,
    required,
    disabled,
    size,
    area,
    line,
    location,
  }) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const label = "Павильон"

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.findPlaces(
          area ? area.building : "",
          area ? area.floor : "",
          line,
          request.input,
        ).then(callback);
      }, 200),
    [area, line],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current) {
      autocompleteService.current = new LocationsService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({input: inputValue}, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.place_number
      }
      disabled={disabled}
      filterOptions={(x) => x}
      size={size}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={location}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onChooseLocation(newValue);
      }}
      noOptionsText="Не найдено"
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth required={required}/>
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <Typography variant="body2" color="text.secondary">
              {option.place_number}
            </Typography>
          </li>
        );
      }}
    />
  );
}