import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  Pagination,
  ReferenceField,
  SelectField,
  SelectInput,
  TextField,
  useRecordContext,
  WrapperField,
} from 'react-admin';
import React from 'react';
import {PassStatuses} from 'src/util';
import {Typography} from '@material-ui/core';
import {Box, Stack} from '@mui/material';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import {PartnerName} from 'src/components/legacy/admin/components/partner_name';

const filters = [
  <SelectInput
      label="По статусу"
      source="status"
      size="small"
      alwaysOn
      choices={PassStatuses}
      variant="outlined"
  />,
  <SelectInput
      label="По флагу"
      source="to_be_agreed"
      size="small"
      alwaysOn
      choices={[
        {id: 'true', name: 'На согласовании'},
      ]}
      variant="outlined"
  />,
];

const ListFlagsField = () => {
  const record = useRecordContext();

  return (
      <Stack
          direction="row"
          alignItems="center"
          sx={{width: '100%'}}
      >
        {record.in_absentia && <MarkAsUnreadIcon titleAccess="Заочно"/>}
        {record.online_store &&
            <LocalGroceryStoreIcon titleAccess="Интернет-магазин"/>}
        {record.half_zone && <StrikethroughSIcon titleAccess="Зона 1/2"/>}
        {record.magnet_card && <CreditScoreIcon titleAccess="Магнитная карта"/>}
      </Stack>
  );
};

const DateUntilField: React.FC = () => {
  const record = useRecordContext();
  const validUntil = new Date(record.valid_until);

  const color =
      validUntil < new Date()
          ? 'tomato'
          : (record.extended ? 'skyblue' : '');

  return (
      <Box color={color}>
        <Typography>
          <DateField source="valid_until"></DateField>
        </Typography>
      </Box>
  );
};

export const PassList: React.FC = () => {
  const postRowStyle = (record: any, index: number) => ({
    backgroundColor: record.status === 'cancelled' ?
        '#ff5252' :
        record.to_be_agreed ? 'orange' : 'none',
  });

  return (
      <List filters={filters} perPage={200} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} />}>
        <Datagrid rowClick="show" rowStyle={postRowStyle}>
          <TextField source="id" label="ID"/>
          <DateField source="created_at" label="Создан"/>
          <WrapperField label="До">
            <DateUntilField/>
          </WrapperField>
          <ReferenceField
              label="Автор"
              reference="users"
              source="creator_user_id"
              link={false}
          >
            <FunctionField
                render={(record: any) => `${record.name} (${record.login})`}
            />
          </ReferenceField>
          <FunctionField
              label="Тип"
              render={() => `Пропуск`}
          />
          <ReferenceField
              label="Торговая точка"
              reference="locations"
              source="location_id"
              link={false}
          />
          <WrapperField label="На кого">
            <PartnerName/>
          </WrapperField>
          <ReferenceField
              label="Должность"
              reference="partner_types"
              source="partner_type_id"
              link={false}
          >
            <FunctionField
                render={(record: any) => `${record.name}`}
            />
          </ReferenceField>
          <ReferenceField
              label="&nbsp;"
              reference="job_titles"
              source="jot_title_id"
              link={false}
          >
            <FunctionField
                render={(record: any) => `${record.name}`}
            />
          </ReferenceField>
          <ListFlagsField/>
          <SelectField
              source="status"
              label="Статус"
              choices={PassStatuses}
          />
        </Datagrid>
      </List>
  );
};