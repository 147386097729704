export interface SosItemDto {
    name: string;
    phone: string;
}

export const sosItems: SosItemDto[] = [
    {
        name: 'Охрана',
        phone: '+7 926 150-51-09',
    },
    {
        name: 'Врачи',
        phone: '+7 915 399-12-69',
    },
    {
        name: 'Пожарники',
        phone: '+7 9',
    },
];

export function phoneToHref(phone: string): string {
    const strippedPhone = phone.replace(/\s|-|\(|\)/g, '');

    return `tel://${strippedPhone}`;
}
