import { baseApi } from './index';
import { JobTitle } from '../models/Passes';

export const jobsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getJobs: builder.query<JobTitle[], string>({
            query: (search) => ({
                url: 'job_titles',
                params: {
                    search,
                },
            }),
        }),
    }),
});

export const { useLazyGetJobsQuery } = jobsApi;
