import {Button, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TokenService, {PermissionCompleteFineCollection, PermissionCreateFineCollection} from "../../services/token_service"
import {useNavigate} from "react-router-dom";

export default function FineStats({stats, onRefresh, onCollection, onCompleteCollection, onExport, disabled}) {
  const navigate = useNavigate()

  if (!stats) {
    return <div/>
  }

  const bgColor = disabled ? "#eee" : "inherit"

  return (
    <Stack direction="column" alignItems="end" sx={{backgroundColor: bgColor, pl: 2}}>
      <Stack direction="row" alignItems="end" sx={{whiteSpace: "nowrap"}}>
        <Tooltip title="За сегодня" arrow placement="top">
          <Typography variant="body2" >
            новых: КОД {stats.today_new_code_sum}
          </Typography>
        </Tooltip>
        <Tooltip title="За сегодня" arrow placement="top">
          <Typography variant="body2" sx={{ml: 2}}>
            отмен: КОД {stats.today_cancel_code_sum}
          </Typography>
        </Tooltip>
      </Stack>
      <Tooltip title="За все время" arrow placement="top">
        <Typography variant="body2">
          На recalculation{stats.pending_collection_count > 1 && ` (${stats.pending_collection_count})`}:
          КОД {stats.collected_sum}
        </Typography>
      </Tooltip>
      {
        (TokenService.hasPermission(PermissionCreateFineCollection) ||
          TokenService.hasPermission(PermissionCompleteFineCollection))
        &&
        <Stack direction="column" alignItems="end" sx={{mt: 0.5}}>
          {
            TokenService.hasPermission(PermissionCreateFineCollection) &&
            <Button variant="outlined" size="small" disabled={disabled || stats.non_collected_sum === 0}
                    onClick={() => onCollection()}>
              Recalculate: КОД {stats.non_collected_sum}
            </Button>
          }

          {
            TokenService.hasPermission(PermissionCompleteFineCollection) &&
            <Button variant="outlined" size="small" disabled={disabled || stats.collected_sum === 0}
                    onClick={() => onCompleteCollection()}>
              Принять recalculation{stats.pending_collection_count > 1 && ` (${stats.pending_collection_count})`}:
              КОД {stats.collected_sum}
            </Button>
          }
        </Stack>
      }


      {stats.last_collect &&
        <Typography variant="caption" align="right">последний recalculation {stats.last_collect}</Typography>
      }

      <Stack direction="row" justifyContent="end" sx={{width: "100%"}}>
        <Tooltip title="Журнал recalculation" arrow placement="bottom">
          <IconButton size="small" component="span" disabled={disabled} onClick={() => {
            navigate("/ui/admin/fines/collection_history")
          }}>
            <AttachMoneyIcon/>
          </IconButton>
        </Tooltip>

        <Tooltip title="Выгрузить в excel" arrow placement="bottom">
          <IconButton size="small" component="span" disabled={disabled} onClick={onExport}>
            <SummarizeIcon/>
          </IconButton>
        </Tooltip>

        <IconButton size="small" component="span" onClick={onRefresh} disabled={disabled}>
          <RefreshIcon/>
        </IconButton>
      </Stack>


    </Stack>
  )
}