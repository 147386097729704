import { baseApi } from './index';
import {
    CreatePassDto,
    Pass,
    PassesResponse,
    PassForm,
} from '../models/Passes';
import { joinByKeys } from '../models/util';
import { PassStatusFilterState } from '../store/passes/passesStatusFilter';

type PassesSearchParams = {
    userId: number;
    status: PassStatusFilterState;
};

export const passesApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getPasses: builder.query<Pass[], PassesSearchParams>({
            query: ({ userId, status }) => ({
                url: 'passes',
                params: {
                    creator_user_id: userId,
                    status: joinByKeys(status),
                },
            }),
            providesTags: ['Passes'],
            transformResponse: (response: PassesResponse) =>
                response.items.map((r) => ({
                    ...r,
                    created_at: r.created_at ?? '',
                    comment: r.comment ?? '',
                    half_zone: r.half_zone ?? false,
                    id: r.id ?? 0,
                    magnet_card: r.magnet_card ?? false,
                    open_close: r.open_close ?? false,
                    in_absentia: r.in_absentia ?? false,
                    to_be_agreed: r.to_be_agreed ?? false,
                    online_store: r.online_store ?? false,
                    was_delayed: r.was_delayed ?? false,
                    valid_until: r.valid_until ?? '',
                })),
        }),
        createPass: builder.mutation<void, PassForm>({
            query: (form) => {
                const body: CreatePassDto = {
                    partner_id: form.partner?.id,
                    partner_name: form.partner?.full_name,
                    comment: form.comment,
                    half_zone: form.halfZone,
                    job_title_id: form.jobTitle?.id,
                    location_id: form.location?.id,
                    magnet_card: form.magnetCard,
                    open_close: form.openClose,
                    pass_type_id: 1,
                    to_be_agreed: form.toBeAgreed,
                    online_store: form.onlineStore,
                    in_absentia: form.inAbsentia,
                    partner_type_id: form.partnerType?.partner_type_id,
                };

                return {
                    url: 'passes',
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['Passes'],
        }),
    }),
});

export const {
    useGetPassesQuery,
    useCreatePassMutation,
} = passesApi;
