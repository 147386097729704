import {useLocation, Navigate} from 'react-router-dom';
import {Outlet} from 'react-router-dom';
import TokenService from '../services/token_service';

export default function RequireAuth() {
  let location = useLocation();

  if (!TokenService.getUser()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/ui/mobile_app/login" state={{from: location}}/>;
  }

  return <Outlet/>;
}

