import {
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { FC } from 'react';
import { useGetFinesQuery } from '../../../api/fine';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
    Fine,
    mapFineStatusToColor,
    mapFineStatusToLabel,
} from '../../../models/Fine';
import { openAppealsDialog } from '../../../store/appeals/appealsDialog';
import { getApiErrorMessage } from '../../../models/ApiError';
import { AppealsStatusFilterState } from '../../../store/appeals/appealsStatusFilter';

const thSx = {
    color: 'grey.600',
    typography: 'subtitle1',
    textAlign: 'left',
};

const tdSx = {
    typography: 'body2',
    pt: 1,
    pb: 1,
    pl: 1,
    pr: 1,
};

export const AppealsTable: FC = () => {
    return (
        <Table stickyHeader size="small" sx={{ paddingBottom: '4rem' }}>
            <TableHead>
                <TableRow>
                    <TableCell sx={thSx}>Дата</TableCell>
                    <TableCell sx={thSx}>Локация</TableCell>
                    <TableCell sx={thSx}>Код</TableCell>
                    <TableCell sx={thSx}>Статус</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                <AppealsTableBody />
            </TableBody>
        </Table>
    );
};

const skeletonArray = new Array(10).fill(0);
const AppealsTableBody: FC = () => {
    const userId = useSelector<RootState, number>(
        (state) => state.auth.userId!
    );

    const statusFilters = useSelector<RootState, AppealsStatusFilterState>(
        (state) => state.appealsStatusFilterSlice
    );

    const { data, isFetching, error } = useGetFinesQuery(
        {
            userId: userId!,
            status: statusFilters,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    if (isFetching) {
        return (
            <>
                {skeletonArray.map((_, i) => (
                    <AppealsTableRow key={`skeleton-${i}`} isLoading={true} />
                ))}
            </>
        );
    }

    if (error) {
        return <DataLoadingError messages={[getApiErrorMessage(error)]} />;
    }

    if (data && data.length > 0) {
        return (
            <>
                {data?.map((row) => (
                    <AppealsTableRow
                        key={row.id}
                        row={row}
                        isLoading={isFetching}
                    />
                ))}
            </>
        );
    }

    return (
        <DataLoadingError
            messages={[
                'Ничего не найдено',
                'Снимите фильтры или создайте обращение',
            ]}
        />
    );
};

type LoadingRowProps = {
    row?: Fine;
    isLoading: true;
};

type DataRowProps = {
    row: Fine;
    isLoading: false;
};

const AppealsTableRow: FC<LoadingRowProps | DataRowProps> = ({
    row,
    isLoading,
}) => {
    const dispatch = useDispatch();
    const openPopup = () => dispatch(openAppealsDialog(row!));

    return (
        <TableRow hover onClick={openPopup}>
            <TableCell sx={tdSx} align="center">
                {isLoading ? <Skeleton variant="text" /> : row.create_date}
            </TableCell>
            <TableCell align="center" sx={tdSx}>
                {isLoading ? <Skeleton variant="text" /> : row.location.name}
            </TableCell>
            <TableCell sx={tdSx} align="left">
                {isLoading ? <Skeleton variant="text" /> : row.code}
            </TableCell>
            <TableCell
                sx={{ ...tdSx, color: mapFineStatusToColor(row?.status) }}
                align="center"
            >
                {isLoading ? (
                    <Skeleton variant="text" />
                ) : (
                    mapFineStatusToLabel(row.status)
                )}
            </TableCell>
        </TableRow>
    );
};

const DataLoadingError: FC<{ messages: string[] }> = ({
    messages = ['Ошибка загрузки данных'],
}) => (
    <Stack
        alignItems="center"
        mt={10}
        sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
        }}
    >
        {messages.map((m) => (
            <Typography variant="subtitle2">{m}</Typography>
        ))}
    </Stack>
);
