import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

export interface ApiError {
    code: number;
    message: string;
}

const DEFAULT_ERROR: ApiError = {
    code: 500,
    message: 'Ошибка сервера',
};

export function getError(error: FetchBaseQueryError): ApiError {
    const { data } = error;

    if (!data || typeof data !== 'object') {
        return DEFAULT_ERROR;
    }

    if (
        hasCode(data) &&
        codeIsNumber(data) &&
        hasMessage(data) &&
        messageIsString(data)
    ) {
        return data;
    }

    return DEFAULT_ERROR;
}

export const getApiErrorMessage: (
    e: ApiError | SerializedError | undefined
) => string = (e) => e?.message ?? '';

// private
function hasCode(obj: {}): obj is {
    [k in keyof Pick<ApiError, 'code'>]: unknown;
} {
    return 'code' in obj;
}

function hasMessage(obj: {}): obj is {
    [k in keyof Pick<ApiError, 'message'>]: unknown;
} {
    return 'message' in obj;
}

function messageIsString(obj: {
    [k in keyof Pick<ApiError, 'message'>]: unknown;
}): obj is { [k in keyof Pick<ApiError, 'message'>]: string } {
    return typeof obj.message === 'string';
}

function codeIsNumber(obj: {
    [k in keyof Pick<ApiError, 'code'>]: unknown;
}): obj is { [k in keyof Pick<ApiError, 'code'>]: number } {
    return typeof obj.code === 'number';
}
