import { Dialog } from '@material-ui/core';
import React, { useState } from 'react';
import { Confirm, SimpleForm } from 'react-admin';
import { useForm } from 'react-hook-form';

type IProps = {
    onClose: () => void;
    onConfirm: (data: any) => void;
    content: string;
    isLoading?: boolean;
};

export const ConfirmDialog: React.FC<IProps> = ({
    content,
    onClose,
    onConfirm,
    isLoading,
}) => {

    return (
        <Confirm
            isOpen={true}
            loading={isLoading}
            title='Подтверждение'
            content={content}
            onConfirm={onConfirm}
            onClose={onClose}
        />
    )
}