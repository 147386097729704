import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { HOST } from '../../config';
import { RootState } from '../../store';

export const baseFetchQuery = fetchBaseQuery({
    baseUrl: HOST + '/api',
    prepareHeaders: (headers, api) => {
        const state = api.getState() as RootState;

        if (state.auth.token) {
            headers.append('Authorization', `Bearer ${state.auth.token}`);
        }

        return headers;
    },
});
