import React, { useEffect } from 'react';
import {QrScanner as Qrscanner} from '@yudiel/react-qr-scanner';
import { styled } from '@mui/material';

interface IProps {
    onResult?: any;
    errorHandler?: any;
    onClose?: any;
}

const ScannerBox = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '10000',
    backgroundColor: 'rgba(0, 0, 0, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 !important',
    
}));

export const QrScanner: React.FC<IProps> = (props) => {
    const {
        onClose,
        onResult,
        errorHandler
    } = props;

    const onBackButtonEvent = (e : any) => {
        e.preventDefault();
        onClose && onClose()
    }

    useEffect(() => {
        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);  
        };
    }, []);

    return (
        <ScannerBox>
            <div style={{ flex: '0 0 500px' }}>
                <Qrscanner
                    onDecode={(result) => {
                        onResult && onResult(result);
                    }}
                    onError={(error) => errorHandler && errorHandler(error?.message)}
                />
            </div>
        </ScannerBox>
    )
}