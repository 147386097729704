import {Autocomplete, TextField, Typography} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import throttle from 'lodash/throttle';
import {UsersService} from "../../services/users_service";

const autocompleteService = {current: null};

export default function ChooseUser(
  {
    onChooseUser,
    required,
    disabled,
    label,
    size,
    user,
  }) {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.findUsers(request.input).then(callback);
      }, 200),
    [user],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current) {
      autocompleteService.current = new UsersService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(user ? [user] : []);
      return undefined;
    }

    fetch({input: inputValue}, (results) => {
      if (active) {
        let newOptions = [];

        if (user) {
          newOptions = [user];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [user, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.name
      }
      disabled={disabled}
      filterOptions={(x) => x}
      size={size}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={user}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onChooseUser(newValue);
      }}
      noOptionsText="Не найдено"
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth required={required}/>
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <Typography variant="body2" color="text.secondary">
              {option.name}
            </Typography>
          </li>
        );
      }}
    />
  );
}