import {fetchUtils, Admin, Resource} from 'react-admin';
import React from 'react';
import jsonServerProvider from 'ra-data-json-server';
import {PassList} from './pass/list';
import * as config from '../../../config';
import authProvider from './auth';
import ru from 'ra-language-russian';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {LocationList} from './locations/list';
import BadgeIcon from '@mui/icons-material/Badge';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import {LocationShow} from './locations/show';
import {JobTitleList} from './job_titles/list';
import {UserList} from './users/list';
import CustomNotification from './components/custom_notification';
import {PassShow} from './pass/show';
import {RootRedirect} from 'src/components/util/rootRedirect';
import {LOCAL_STORAGE_KEY} from 'src/config';

const httpClient = (url, options = {}) => {
  const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  options.user = {
    authenticated: true,
    token: 'Bearer ' + token.access_token,
  };
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = jsonServerProvider(config.ADMIN_HOST, httpClient);

const translations = {ru};
export const i18nProvider = polyglotI18nProvider(locale => translations[locale],
    'ru');

export function AdminRoot() {
  return (
      <Admin
          notification={CustomNotification}
          basename="/ui/admin_new"
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          locale="ru"
          loginPage={<RootRedirect/>}
      >
        <Resource
            name="passes"
            list={PassList}
            show={PassShow}
            options={{label: 'Визитки'}}
            icon={BadgeIcon}
        />
        <Resource
            name="locations"
            list={LocationList}
            show={LocationShow}
            options={{label: 'Торговые точки'}}
            icon={LocationOnIcon}
            recordRepresentation="name"
        />
        <Resource
            list={JobTitleList}
            options={{label: 'Должности'}}
            name="job_titles"
            recordRepresentation="name"
        />
        <Resource
            name="pass_types"
            recordRepresentation="name"
        />
        <Resource
            name="partners"
            recordRepresentation="name"
        />
        <Resource
            name="partner_types"
            recordRepresentation="name"
        />
        <Resource
            list={UserList}
            options={{label: 'Пользователи'}}
            icon={PersonIcon}
            name="users"
            recordRepresentation="name"
        />
      </Admin>
  );
}
