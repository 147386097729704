import { useEffect, useState } from 'react';
import { User } from '../models/User';
import { useLazyGetPersonQuery } from '../api/person';

export function usePersonSuggest() {
    const [searchPerson, setSearchPerson] = useState('');
    const [person, _setPerson] = useState<User | null>(null);
    const [triggerSearchPerson, { data: personSuggest }] =
        useLazyGetPersonQuery();
    // const setSearchPerson = (e: any, value: string) => _setSearchPerson(value);
    const setPerson = (_: any, person: User | null) => _setPerson(person);

    useEffect(() => {
        if (searchPerson.length === 0) {
            return;
        }

        triggerSearchPerson(searchPerson);
    }, [searchPerson, triggerSearchPerson]);

    return {
        searchPerson,
        setSearchPerson,
        person,
        setPerson,
        personSuggest,
    };
}
