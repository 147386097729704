import instance from "./api";

export class UsersService {
  async findUsers(name) {
    if (name === "") {
      return [];
    }

    const res = await instance.get(`/users/?search=${name}`)
    return res.data
  }
}
