import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Fine } from '../../models/Fine';

type State = null | Fine;

export const appealsDialogSlice = createSlice({
    name: 'appealsDialog',
    initialState: null as State,
    reducers: {
        open(state, action: PayloadAction<Fine>) {
            return action.payload;
        },
        close() {
            return null;
        },
    },
});

export const { open: openAppealsDialog, close: closeAppealsDialog } =
    appealsDialogSlice.actions;
