import { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/dist/query/react';
import { ApiError, getError } from '../../models/ApiError';
import { RootState } from '../../store';
import { logout, refresh } from '../../store/auth';
import { validateRefreshToken } from '../../models/Auth';
import { baseFetchQuery } from './baseFetchQuery';

export const baseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    ApiError
> = async (args, api, extraOptions) => {
    let { error, data } = await baseFetchQuery(args, api, extraOptions);

    if (error) {
        if (error.status === 401) {
            return processAuthError(args, api, extraOptions);
        }

        return {
            error: getError(error),
        };
    }

    return {
        data,
    };
};

async function processAuthError(
    ...args: Parameters<typeof baseQuery>
): Promise<ReturnType<typeof baseQuery>> {
    const [a, api, extraOptions] = args;
    const notAuthError = {
        error: {
            code: 401,
            message: 'Not Authorized',
        },
    };
    const refreshToken = (api.getState() as RootState).auth.refreshToken;

    if (!refreshToken) {
        api.dispatch(logout());

        return notAuthError;
    }

    const refreshResult = await baseFetchQuery(
        {
            url: 'refresh_token',
            body: {
                refresh_token: refreshToken,
            },
            method: 'POST',
        },
        api,
        extraOptions
    );

    if (validateRefreshToken(refreshResult.data)) {
        api.dispatch(
            refresh({
                access_token: refreshResult.data.access_token,
                refresh_token: refreshResult.data.refresh_token,
            })
        );

        return baseQuery(a, api, extraOptions);
    } else {
        api.dispatch(logout());

        return notAuthError;
    }
}
