import ChooseEntity from "./choose_entity";
import PersonsService from "../../services/persons_service";


export default function ChoosePerson(props) {
  return (
    <ChooseEntity
      requester={PersonsService.findFineReasons}
      {...props}
    />
  );
}