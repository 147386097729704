import React, { FC, useEffect, useState } from 'react';
import { useDialog } from '../../../hooks/useDialog';
import { Backdrop, Box, Stack } from '@mui/material';
import { motion, PanInfo, useDragControls } from 'framer-motion';
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

export const Images: FC<{
    images: string[];
    children?: React.ReactElement;
}> = ({ images, children }) => {
    const [isPreview, openPreview, closePreview] = useDialog();
    const dragControls = useDragControls();
    const [showImage, setShowImage] = useState<boolean>();
    const [sliders, setSliders] = useState<{
        src: string;
    }[]>([]);
    const [sliderIndex, setSliderIndex] = useState(0);

    const toggleSliderOpen = (state: any) => () => setShowImage(state);
    const updateSliderIndex = ({ index: current }: {index: any}) => setSliderIndex(current);
  
    useEffect(() => {
        setSliders(images?.map(item => ({
            src: item
        })))
    }, [])

    return (
        <>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    height: 64,
                    paddingBottom: '5rem',
                }}
            >
                {children}
                {showImage 
                    && <Lightbox
                        open
                        close={toggleSliderOpen(false)}
                        index={sliderIndex}
                        slides={sliders}
                        // plugins={[Zoom]}
                        animation={{ fade: 0 }}
                        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
                    />
                }
                {images.map((url, i) => (
                    <Box
                        key={i}
                        sx={{
                            width: 64,
                            height: 64,
                            boxSizing: 'border-box',
                            objectFit: 'cover',
                            backgroundColor: '#ebebeb',
                            flex: '0 0 auto',
                        }}
                        component="img"
                        src={url}
                        onClick={() => {
                            setShowImage(true);
                            setSliderIndex(i);
                        }}
                    />
                ))}
            </Stack>
        </>
    );
};
