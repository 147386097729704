import {useLayoutEffect, useState} from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Navigate, Route, Router, Routes} from 'react-router-dom';
import setupInterceptors from './services/setup_interceptors';
import Login from './routes/login';
import RequireAuth from './routes/require_auth';
import history from './routes/history';
import AdminFinesList from './routes/admin_fines_list';
import AdminFinesEdit from './routes/admin_fines_edit';
import {CssBaseline} from '@mui/material';
import AdminWrapper from './routes/admin_wrapper';
import AdminFinesCollection from './routes/admin_fines_collection';
import RequirePermission from './routes/require_permission';
import {PermissionAdmin, PermissionCreateFine,} from './services/token_service';
import AdminFinesCompleteCollection from './routes/admin_fines_complete_collection';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import 'moment/locale/ru';
import AdminFinesCollectionHistory from './routes/admin_fines_complete_history';
import {AdminRoot} from './components/legacy/admin';
import {MobileRoot} from './components/legacy/mobile_new';
import 'typeface-roboto';
import {MobileApp} from "./components/mobile/app";
import {createRoot} from "react-dom/client";
import {RootRedirect} from "src/components/util/rootRedirect";

const CustomRouter = ({history, ...props}: any) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router
            {...props}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    );
};

setupInterceptors();
const root = createRoot(document.getElementById('root')!);
root.render(
    <CustomRouter history={history}>
        <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
            <CssBaseline/>
            <Routes>
                <Route path="/ui">
                    <Route path="" element={<RootRedirect/>}/>
                    <Route element={<RequireAuth/>}>
                        <Route
                            element={
                                <RequirePermission permission={PermissionCreateFine}/>}
                        >
                            <Route path="mobile" element={<RootRedirect/>}>
                                {/*<Route path="" element={<MobileHome/>}/>*/}
                                {/*<Route path="fine_history" element={<MobileFineHistory/>}/>*/}
                                {/*<Route path="new_fine" element={<CreateFine/>}/>*/}
                                {/*<Route*/}
                                {/*    path="new_fine_success"*/}
                                {/*    element={<CreateFineSuccess/>}*/}
                                {/*/>*/}
                                {/*<Route path="pass_history" element={<MobilePassHistory/>}/>*/}
                                {/*<Route path="card_search" element={<MobileCardSearch/>}/>*/}
                                {/*<Route path="create_pass">*/}
                                {/*    <Route path="" element={<MobileCreatePassHome/>}/>*/}
                                {/*    <Route path=":id" element={<MobileCreatePass/>}/>*/}
                                {/*</Route>*/}
                                {/*<Route*/}
                                {/*    path="create_pass_success"*/}
                                {/*    element={<CreatePassSuccess/>}*/}
                                {/*/>*/}
                            </Route>
                        </Route>
                        <Route
                            element={<RequirePermission permission={PermissionAdmin}/>}
                        >
                            <Route path="admin" element={<AdminWrapper/>}>
                                <Route
                                    path="" element={<Navigate to="/ui/admin/fines" replace/>}
                                />
                                <Route path="fines">
                                    <Route path="" element={<AdminFinesList/>}/>
                                    <Route path=":id" element={<AdminFinesEdit/>}/>
                                    <Route path="collection" element={<AdminFinesCollection/>}/>
                                    <Route
                                        path="complete_collection"
                                        element={<AdminFinesCompleteCollection/>}
                                    />
                                    <Route
                                        path="collection_history"
                                        element={<AdminFinesCollectionHistory/>}
                                    />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                    <Route path="admin_new/*" element={<AdminRoot/>}/>
                    <Route path="mobile_new/*" element={<MobileRoot/>}/>
                    <Route path="mobile_app/*" element={<MobileApp/>}/>
                    <Route path="login" element={<Login/>}/>
                </Route>
            </Routes>
        </LocalizationProvider>
    </CustomRouter>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
