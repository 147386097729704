import {
  Box, Button, Fab, Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import {FormatStatus} from '../../../../util';
import AddIcon from '@mui/icons-material/Add';

const thSx = {
  color: 'grey.600',
  typography: 'subtitle1',
  textAlign: 'left',
};

const tdSx = {
  typography: 'body2',
  pt: 1,
  pb: 1,
  pl: 1,
  pr: 1,
};

export const FinesIndex = () => {
  const rows = [
    {
      'id': 1,
      'create_date': '05.06.2022',
      'location': {
        'name': 'БЦТ-2-2-1',
      },
      'status': 'repaid',
      'code': 12,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
    {
      'id': 2,
      'create_date': '04.06.2022',
      'location': {
        'name': 'АТЦ-3-5',
      },
      'status': 'non_paid',
      'code': 24,
    },
  ];
  for (let i = 0; i < rows.length; i++) {
    rows[i].id = i + 1;
  }
  // <Box component="li" sx={{mb: 2, pr: 3}} key={item.id}>
  //   <b>{`#${item.id}`}</b>, {FormatFineReason(item.fine_reason)}
  //   <br/>
  //   {item.location.name}
  //
  //   <Typography fontSize="small" color="grey.500">
  //     {item.create_date + ' ' + item.create_time}, {FormatStatus(item.status)}
  //   </Typography>
  // </Box>;

  return (
      <Box
          sx={{
            position: 'relative',
            height: '100%',
          }}
      >
        <Box
            sx={{
              p: 2,
              height: '100%',
              overflowY: 'scroll',
            }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={thSx}>
                  Дата
                </TableCell>
                <TableCell sx={thSx}>
                  Локация
                </TableCell>
                <TableCell sx={thSx}>
                  Код
                </TableCell>
                <TableCell sx={thSx}>
                  Статус
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                  <TableRow
                      key={row.id}
                  >
                    <TableCell
                        sx={tdSx}
                        align="center"
                    >{row.create_date}</TableCell>
                    <TableCell
                        align="center"
                        sx={tdSx}
                    >{row.location.name}</TableCell>
                    <TableCell sx={tdSx} align="left">{row.code}</TableCell>
                    <TableCell sx={tdSx} align="center">
                      {FormatStatus(row.status)}
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

        <Button
            variant="contained"
            size={'large'}
            fullWidth={true}
            sx={{
              position: 'absolute',
              bottom: '40px',
              width: '80%',
              left: '10%',
            }}
        >
          Создание обращение &nbsp;<AddIcon/>
        </Button>
      </Box>
  );
};