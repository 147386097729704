import React, { FC, useState, useEffect } from 'react';
import { SlideUpTransition } from '../common/SlideUpTransition';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    ClickAwayListener,
    Collapse,
    Dialog,
    FormControlLabel,
    FormGroup,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { ArrowBack, ExpandMore, QuestionMark } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { OptionTooltip, PassForm } from '../../../models/Passes';
import {
    Control,
    Controller,
    FieldPathByValue,
    FieldValues,
    RegisterOptions,
    useController,
    useForm,
    useWatch,
} from 'react-hook-form';
import { LocationForm } from '../common/LocationForm';
import { useFormReset } from '../../../hooks/useFormReset';
import { useJobTitleSuggest } from '../../../hooks/useJobTitleSuggest';
import { requiredRule } from '../../../models/Form';
import { useCreatePassMutation } from '../../../api/passes';
import { getApiErrorMessage } from '../../../models/ApiError';
import { usePartnerSuggest } from 'src/hooks/usePartnerSuggest';
import { useGetPartnerTypesQuery, useGetPartnerTypesSmartQuery } from 'src/api/partner';
import { IPartnerType } from 'src/models/Partner';

type CreateCardProps = {
    open: boolean;
    onClose: () => void;
};

interface FormControl {
    control: Control<PassForm>;
}

export const CreateCard: FC<CreateCardProps> = ({ open, onClose }) => {
    const [additionOpen, setAdditionOpen] = useState(false);
    const [partnerType, setPartnerType] = useState<IPartnerType>();
    const toggleAdditionOpen = () => setAdditionOpen((open) => !open);
    const { control, handleSubmit, reset, setValue } = useForm<PassForm>({
        defaultValues: {
            magnetCard: false,
            partner: {
                full_name: '',
                id: 0,
                import_id: ''
            },
            openClose: true,
            halfZone: false,
            comment: '',
            inAbsentia: false,
            toBeAgreed: false,
            onlineStore: false,
        },
    });
    const [createPass, { isLoading: isCreating, error }] =
        useCreatePassMutation();
    const creatingErrorMessage = getApiErrorMessage(error);

    const partnerTypeHandler = (data: IPartnerType) => {
        setPartnerType(data);
    }
    
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={SlideUpTransition}
        >
            <>
                <Stack
                    direction="column"
                    p={2}
                    spacing={3}
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: '#fff',
                        zIndex: 2,
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}
                    >
                        <Button
                            onClick={onClose}
                            variant="contained"
                            sx={{
                                minWidth: 'auto',
                                width: '3rem',
                            }}
                        >
                            <ArrowBack />
                        </Button>
                    </Stack>
                </Stack>
                <form
                    onSubmit={handleSubmit((data) => {
                        createPass(data)
                            .unwrap()
                            .then(() => {
                                reset();
                                onClose();
                            });
                    })}
                >
                    <Stack
                        sx={{ marginTop: '5rem', padding: '0 1rem 1rem' }}
                        spacing={1}
                    >
                        <Stack direction="row" spacing={1}>
                            <Name control={control} />
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{
                                    minWidth: 'auto',
                                    height: '40px',
                                    alignSelf: 'flex-start',
                                }}
                                onClick={toggleAdditionOpen}
                            >
                                <ExpandMore
                                    component={motion.svg}
                                    initial={{
                                        rotate: '0deg',
                                    }}
                                    animate={{
                                        rotate: additionOpen
                                            ? '180deg'
                                            : '0deg',
                                    }}
                                    transition={{ ease: 'linear' }}
                                />
                            </Button>
                        </Stack>
                        <Collapse in={additionOpen}>
                            <Box pl={1}>
                                <FormGroup>
                                    <OptionCheckbox
                                        label="Заочно"
                                        tooltip={OptionTooltip.inAbsentia}
                                        control={control}
                                        name="inAbsentia"
                                    />
                                    <OptionCheckbox
                                        label="На согласовании"
                                        tooltip={OptionTooltip.toBeAgreed}
                                        control={control}
                                        name="toBeAgreed"
                                    />
                                    <OptionCheckbox
                                        label="Интернет-магазин"
                                        tooltip={OptionTooltip.onlineStore}
                                        control={control}
                                        name="onlineStore"
                                    />
                                </FormGroup>
                            </Box>
                        </Collapse>
                        <LocationForm
                            qrCode={true}
                            control={control}
                            name="location"
                            setValue={setValue}
                        />

                        <Controller
                            control={control}
                            name="halfZone"
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            size="small"
                                        />
                                    }
                                    label="Зона 1/2"
                                />
                            )}
                        />

                        <PartnerTypes
                            control={control}
                            changeHandler={partnerTypeHandler}
                            setValue={setValue}
                        />

                        {partnerType?.choose_job_title
                            && <JobTitle control={control} />
                        }
                        
                        <Controller
                            control={control}
                            name="magnetCard"
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            size="small"
                                        />
                                    }
                                    label="Магнитная карта"
                                />
                            )}
                        />

                        <OpenClose control={control} />

                        <Controller
                            name="comment"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    id="comment"
                                    label="Комментарий"
                                    minRows={3}
                                    fullWidth
                                    multiline
                                    {...field}
                                />
                            )}
                        />

                        <Typography color="red">
                            {creatingErrorMessage}
                        </Typography>

                        <Button
                            variant="contained"
                            size="large"
                            type="submit"
                            disabled={isCreating}
                        >
                            Выписать
                        </Button>
                    </Stack>
                </form>
            </>
        </Dialog>
    );
};

type OptionCheckboxProps<
    TFieldValues extends FieldValues,
    TPath extends FieldPathByValue<TFieldValues, boolean>
> = {
    label: string;
    tooltip: string;
    control: Control<TFieldValues>;
    name: TPath;
};

const OptionCheckbox = <
    TFieldValues extends FieldValues,
    TPath extends FieldPathByValue<TFieldValues, boolean>
>({
    label,
    tooltip,
    control,
    name,
}: OptionCheckboxProps<TFieldValues, TPath>) => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <Stack direction="row" alignItems="center">
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                checked={field.value}
                                size="small"
                            />
                        }
                        label={label}
                    />
                )}
            />
            <Box
                onClick={handleTooltipOpen}
                sx={{
                    background: '#E3F2FD',
                    width: '16px',
                    height: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '8px',
                    marginLeft: '-0.5rem',
                }}
            >
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                        title={tooltip}
                        arrow
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                    >
                        <QuestionMark
                            sx={{
                                width: '10px',
                                color: '#2196F3',
                            }}
                        />
                    </Tooltip>
                </ClickAwayListener>
            </Box>
        </Stack>
    );
};

const PartnerTypes: FC<{
    control: any;
    changeHandler?: any;
    setValue?: any;
}> = ({ control, changeHandler, setValue }) => {
    const partner: any = usePartnerSuggest();
    const { data } = useGetPartnerTypesSmartQuery(null);

    return (
        <Controller
            control={control}
            rules={requiredRule}
            name="partnerType"
            render={({
                field: { onChange, ...field },
                formState: { errors },
            }) => (
                <Autocomplete
                    fullWidth
                    size="small"
                    id="coauthor"
                    options={data || []}
                    loadingText="Загрузка..."
                    noOptionsText="Ничего не найдено"
                    getOptionLabel={(option) => option.name}
                    filterOptions={(x) => x}
                    onChange={(e, j) => {
                        onChange(j);
                        changeHandler(j);
                        if (j.job_title_id && !j.choose_job_title) {
                            setValue('jobTitle', {
                                id: j.job_title_id,
                                name: j.name
                            });
                        }
                    }}
                    value={partner.partnetTypeId}
                    isOptionEqualToValue={(o, v) => o.id === v?.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            {...field}
                            label="Должность"
                            error={!!errors.partnerType}
                        />
                    )}
                />
            )}
        />
    );
};

const JobTitle: FC<FormControl> = ({ control }) => {
    const jobTitle: any = useJobTitleSuggest();
    const value = useWatch({ control, name: 'jobTitle' });

    useFormReset(value, () => {
        jobTitle.setJobTitle(null);
        jobTitle.setSearchJobTitle('');
    });

    return (
        <Controller
            control={control}
            rules={requiredRule}
            name="jobTitle"
            render={({
                field: { onChange, ...field },
                formState: { errors },
            }) => (
                <Autocomplete
                    fullWidth
                    size="small"
                    id="coauthor"
                    options={jobTitle.jobTitleSuggest ?? []}
                    loadingText="Загрузка..."
                    noOptionsText="Ничего не найдено"
                    getOptionLabel={(option) => option.name}
                    filterOptions={(x) => x}
                    onChange={(e, j) => {
                        onChange(j);
                        jobTitle.setJobTitle(j);
                    }}
                    isOptionEqualToValue={(o, v) => o.id === v?.id}
                    onInputChange={(e, v) => {
                        jobTitle.setSearchJobTitle(v);
                    }}
                    value={jobTitle.jobTitle}
                    inputValue={jobTitle.searchJobTitle}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            {...field}
                            label="Должность"
                            error={!!errors.jobTitle}
                            helperText={errors.jobTitle?.message ?? ''}
                        />
                    )}
                />
            )}
        />
    );
};

const Name: FC<FormControl> = ({ control }) => {
    const partner: any = usePartnerSuggest();
    const value = useWatch({ control, name: 'partner' });

    useFormReset(value, () => {
        partner.setPartner(null);
        partner.setSearchPartner('');
    });

    let timeout: any;

    const promiseOptions = (inputValue: string) =>
        new Promise<any[]>((resolve) => {
            clearTimeout(timeout);

            timeout = setTimeout(async () => {
                const { data } = await partner.triggerSearchPartner(inputValue);
                resolve(data);
            }, 500);
        });

    return (
        <Controller
            control={control}
            rules={requiredRule}
            name="partner"
            render={({
                field: { onChange, ...field },
                formState: { errors },
            }) => (
                <div style={{ width: '100%' }}>
                    <AsyncCreatableSelect
                        options={partner.partnerSuggest ?? []}
                        placeholder={'ФИО или ID*'}
                        noOptionsMessage={() => 'Ничего не найдено'}
                        loadingMessage={() => 'Загрузка...'}
                        loadOptions={promiseOptions}
                        getOptionLabel={(e) => {
                            if (e.full_name) {
                                return `${e.full_name} (${e.birthday})`
                            } else {
                                return e.value
                            }
                        }}
                        onChange={(e) => {
                            if (e.full_name) {
                                onChange(e);
                                partner.setPartner(e);
                            } else {
                                onChange({
                                    full_name: e.value
                                });
                                partner.setPartner({
                                    full_name: e.value
                                });
                            }
                        }}
                        getOptionValue={(e) => e}
                        menuPortalTarget={document.body} 
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>
            )}
        />
    );
};

const OpenClose: FC<FormControl> = ({ control }) => {
    const { field } = useController({ control, name: 'openClose' });

    return (
        <Stack direction="row" spacing={1}>
            <Box
                sx={{
                    flex: 1,
                    backgroundColor: field.value
                        ? '#E8F5E9'
                        : 'rgba(0, 0, 0, 0.12)',
                    color: field.value ? '#4CAF50' : 'rgba(0, 0, 0, 0.26)',
                    transition: 'background-color 0.3s, color 0.3s',
                    textAlign: 'center',
                    padding: '0.5rem',
                    borderRadius: '2rem',
                }}
                onClick={() => field.onChange(true)}
            >
                Допущен
            </Box>
            <Box
                sx={{
                    flex: 1,
                    backgroundColor: !field.value
                        ? '#FFEBEE'
                        : 'rgba(0, 0, 0, 0.12)',
                    color: !field.value ? '#F44336' : 'rgba(0, 0, 0, 0.26)',
                    transition: 'background-color 0.3s, color 0.3s',
                    textAlign: 'center',
                    padding: '0.5rem',
                    borderRadius: '2rem',
                }}
                onClick={() => field.onChange(false)}
            >
                Не допущен
            </Box>
        </Stack>
    );
};
