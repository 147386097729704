import {
  FunctionField,
  ReferenceField,
  TextField,
  useRecordContext,
} from 'react-admin';
import {Typography} from '@mui/material';

export const PartnerName = () => {
  const record = useRecordContext();

  if (record.partner_id) {
    return (
        <ReferenceField
            reference="partners"
            source="partner_id"
            link={false}
        >
          <FunctionField
              render={record => `${record.full_name} (${record.birthday})`}
          />
        </ReferenceField>
    );
  }
  return (
      <FunctionField
          render={record => `${record.partner_name} (Контрагент не найден)`}
      />
  );
};