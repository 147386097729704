import { IconButton, Stack, Typography } from '@mui/material';
import { phoneToHref, SosItemDto, sosItems } from '../../../models/Sos';
import { FC } from 'react';
import { Call, ContentCopy } from '@mui/icons-material';

export const Sos = () => {
    return (
        <Stack spacing={2} m={2}>
            {sosItems.map((item, i) => (
                <SosItem key={i} item={item} />
            ))}
        </Stack>
    );
};

const SosItem: FC<{ item: SosItemDto }> = ({ item }) => {
    const call = () => {
        const link = document.createElement('a');

        link.href = phoneToHref(item.phone);
        link.click();
    };
    const copyPhoneToClipboard = () =>
        navigator.clipboard.writeText(item.phone);

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                border: '1px solid #1976D2',
                borderRadius: '8px',
            }}
            p={1}
        >
            <IconButton onClick={call}>
                <Call sx={{ color: '#1976D2' }} />
            </IconButton>
            <Stack flex={1}>
                <Typography sx={{ color: '#1976D2' }}>{item.name}</Typography>
                <Typography>{item.phone}</Typography>
            </Stack>
            <IconButton onClick={copyPhoneToClipboard}>
                <ContentCopy />
            </IconButton>
        </Stack>
    );
};
