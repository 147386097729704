import {useCallback, useContext, useEffect, useState} from "react";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import FinesService from "../services/fines_service"
import {
  Box, FormControl,
  Grid, Modal, Stack,
  Table, TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import {useParams} from "react-router-dom";
import {
  FormatError, FormatFineType, FormatStatus,
  StatusCancelled,
  StatusCollection,
  StatusNonPaid,
  StatusPool,
  StatusRepaid
} from "../util";
import {GlobalState} from "../GlobalState";
import LoadingButton from "@mui/lab/LoadingButton";
import LoadingWrapper from "../components/legacy/loading_screen";
import TokenService, {
  PermissionFineChangeCode, PermissionFineChangeLocation,
  PermissionFineChangeStatus, PermissionFineChangeType,
  PermissionFineStatusCancelled, PermissionFineStatusCollection,
  PermissionFineStatusNonPaid, PermissionFineStatusPool,
  PermissionFineStatusRepaid
} from "../services/token_service"
import ChooseArea from "../components/legacy/choose_area";
import ChooseLine from "../components/legacy/choose_line";
import ChoosePlace from "../components/legacy/choose_place";
import ChooseFineReason from "../components/legacy/choose_fine_reason";
import ChoosePerson from "../components/legacy/choose_person";
import "yet-another-react-lightbox/styles.css";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  border: '1px solid #000',
  boxShadow: 24,
  backgroundColor: "#eee",
};

const imageStyle = {
  width: 200,
  border: "1px solid",
  cursor: "pointer",
  mr: 2
}

export default function AdminFinesEdit() {
  const state = useContext(GlobalState)
  const [fineTypes] = state.fineTypesAPI.fineTypes
  const {id} = useParams()
  const [loading, setLoading] = useState(true)
  const [fine, setFine] = useState(null)
  const [fineHistory, setFineHistory] = useState([])
  const [loadFineError, setLoadFineError] = useState(false)

  const [newFineReason, setNewFineReason] = useState(null)
  const [newFineReasonID, setNewFineReasonID] = useState(0)
  const [newFineTypeID, setNewFineTypeID] = useState(0)
  const [newStatus, setNewStatus] = useState("")
  const [newCode, setNewCode] = useState("")
  const [newArea, setNewArea] = useState(null)
  const [newLine, setNewLine] = useState("")
  const [newLocation, setNewLocation] = useState(null)
  const [newCoauthor, setNewCoauthor] = useState(null)
  const [updateComment, setUpdateComment] = useState("")

  const [modalOpen, setModalOpen] = useState(false)
  const [modalImage, setModalImage] = useState("")

  const [updating, setUpdating] = useState(false)
  const [updateMessage, setUpdateMessage] = useState("")
  const [updateError, setUpdateError] = useState(false);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [sliders, setSliders] = useState([]);

  let availableStatuses = []
  if (TokenService.hasPermission(PermissionFineStatusNonPaid)) {
    availableStatuses.push(StatusNonPaid)
  }
  if (TokenService.hasPermission(PermissionFineStatusPool)) {
    availableStatuses.push(StatusPool)
  }
  if (TokenService.hasPermission(PermissionFineStatusCollection)) {
    availableStatuses.push(StatusCollection)
  }
  if (TokenService.hasPermission(PermissionFineStatusRepaid)) {
    availableStatuses.push(StatusRepaid)
  }
  if (TokenService.hasPermission(PermissionFineStatusCancelled)) {
    availableStatuses.push(StatusCancelled)
  }
  if (fine && availableStatuses.indexOf(fine.status) === -1) {
    availableStatuses.push(fine.status)
  }

  const finalStatus = fine && (fine.status === StatusRepaid || fine.status === StatusCollection)
  const canChangeStatus = TokenService.hasPermission(PermissionFineChangeStatus) && !finalStatus
  const canChangeType = TokenService.hasPermission(PermissionFineChangeType) && !finalStatus
  const canChangeCode = TokenService.hasPermission(PermissionFineChangeCode) && !finalStatus
  const canChangeLocation = TokenService.hasPermission(PermissionFineChangeLocation) && !finalStatus
  const canChange = (canChangeStatus || canChangeType || canChangeCode || canChangeLocation) && !finalStatus

  const toggleSliderOpen = (state) => () => setModalOpen(state);
  const updateSliderIndex = ({ index: current }) => setSliderIndex(current);

  const loadFine = useCallback(async () => {
    setLoading(true)
    setLoadFineError(false)

    try {
      const loadedFine = await FinesService.getFine(id)
      setFine(loadedFine)
      setNewFineTypeID(loadedFine.fine_type.id)
      setNewFineReason(loadedFine.fine_reason)
      setNewFineReasonID(loadedFine.fine_reason.id)
      setNewStatus(loadedFine.status)
      setNewCode(loadedFine.code)
      setNewArea({
        building: loadedFine.location.building,
        floor: loadedFine.location.floor,
      })
      setNewLine(loadedFine.location.line)
      setNewLocation(loadedFine.location)
      setNewCoauthor(loadedFine.coauthor_user)

      setFineHistory(await FinesService.getFineHistory(id))

        setSliders([
            {
                src: loadedFine.pass_photo
            },
            {
                src: loadedFine.violation_photo
            },
        ])
    } catch (e) {
      setLoadFineError(true)
    } finally {
      setLoading(false)
    }
  }, [id])

  const updateFine = async () => {
    setUpdating(true)
    try {
      await FinesService.updateFine({
        id: id,
        status: newStatus,
        code: newCode,
        fineTypeID: newFineTypeID,
        fineReasonID: newFineReasonID,
        comment: updateComment,
        locationID: newLocation.id,
        coauthorID: newCoauthor ? newCoauthor.id : "",
      })
      setUpdateError(false)
      setUpdateMessage("Изменения сохранены")
      setFineHistory(await FinesService.getFineHistory(id))
      window.close()
    } catch (e) {
      setUpdateError(true)
      setUpdateMessage(FormatError(e))
    } finally {
      setUpdating(false)
    }
  }

  useEffect(() => {
    loadFine()
  }, [loadFine])

  const coauthorChanged = fine && ((!fine.coauthor_user && newCoauthor) ||
    (fine.coauthor_user && !newCoauthor) ||
    (fine.coauthor_user && newCoauthor && fine.coauthor_user.id !== newCoauthor.id))

  const hasChanges = fine != null &&
    (
      (fine.fine_type.id !== newFineTypeID) ||
      (fine.fine_reason.id !== newFineReasonID) ||
      (fine.status !== newStatus) ||
      (fine.code !== parseInt(newCode)) ||
      (newLocation && newLocation.id !== fine.location.id) ||
      coauthorChanged
    )

  const shouldShowComment = (fine &&
    ((newStatus && fine.status !== newStatus && newStatus !== StatusPool) ||
      (newLocation && newLocation.id !== fine.location.id) ||
      coauthorChanged
    )
  )
  console.log('modal:', modalOpen);
  

  return (
    <LoadingWrapper loading={loading} error={loadFineError} errorText={"Ошибка при загрузке штрафа"}>
      {fine &&
        <Box component={"form"}
             onSubmit={e => {
               e.preventDefault()
               updateFine().catch(console.error)
             }}>
          <Stack direction="row">
            <Grid item sx={{
              '& th': {textAlign: "right", verticalAlign: "top"},
              '& th, & td': {p: 1},
              pr: 2,
              flexBasis: '36rem'
            }} zeroMinWidth>
              <table>
                <tbody>
                <tr>
                  <th>Номер</th>
                  <td>{`#${fine.id}`}</td>
                </tr>
                <tr>
                  <th>Создан</th>
                  <td>{`${fine.create_date} ${fine.create_time}`}</td>
                </tr>
                <tr>
                  <th>Локация</th>
                  <td>{fine.location.name}</td>
                </tr>
                <tr>
                  <th>Автор</th>
                  <td>{fine.creator_user.name}</td>
                </tr>
                <tr>
                  <th>Соавтор</th>
                  <td>{fine.coauthor_user ? fine.coauthor_user.name : "-"}</td>
                </tr>
                <tr>
                  <th>Описание</th>
                  <td>{fine.description}</td>
                </tr>
                <tr>
                  <th>Бюро</th>
                  <td>{fine.external_id ? `ID ${fine.external_id}` : '-'}
                    {fine.external_id &&
                      <span>, данные обновлены: {fine.sync_required ? 'нет' : 'да'}</span>
                    }
                  </td>
                </tr>
                {(fine.pass_photo || fine.violation_photo) &&
                  <tr>
                    <th>Фото</th>
                    <td>
                        {modalOpen 
                            && <Lightbox
                                open
                                close={toggleSliderOpen(false)}
                                index={sliderIndex}
                                slides={sliders}
                                plugins={[Zoom]}
                                animation={{ fade: 0 }}
                                controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
                            />
                        }
                      {fine.pass_photo &&
                        <Box
                          component="img"
                          sx={imageStyle}
                          alt="Фото пропуска"
                          src={fine.pass_photo}
                          onClick={e => {
                            setModalOpen(true)
                            setSliderIndex(0);
                          }}
                        />
                      }
                      {fine.violation_photo &&
                        <Box
                          component="img"
                          sx={imageStyle}
                          alt="Фото нарушения"
                          src={fine.violation_photo}
                          onClick={e => {
                            setModalOpen(true)
                            setSliderIndex(1);
                          }}
                        />
                      }
                    </td>
                  </tr>
                }
                </tbody>
              </table>
            </Grid>
            <Stack direction="column" spacing={2} sx={{p: 2}}>
              {updateMessage &&
                <Grid item>
                  <Typography
                    variant="body2"
                    color={updateError ? "error.main" : "success.main"}
                  >{updateMessage}
                  </Typography>
                </Grid>
              }
              <Grid item sx={{width: 350}}>
                <ChooseFineReason
                  size="small"
                  label="Причина"
                  variant="outlined"
                  value={newFineReason}
                  onChange={el => {
                    setNewFineReason(el)
                    setNewFineReasonID(el.id)
                  }}
                  disabled={!canChangeType}
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  select
                  label="Код"
                  size="small"
                  variant="outlined"
                  SelectProps={{
                    native: true,
                  }}
                  value={newFineTypeID}
                  onChange={e => {
                    const targetID = parseInt(e.target.value)
                    setNewFineTypeID(targetID)
                    const foundType = fineTypes.find(el => el.id === targetID)
                    if (foundType) {
                      setNewCode(foundType.code)
                    }
                  }}
                  disabled={!canChangeType}
                  required
                >
                  {fineTypes.map((typ) => (
                    <option key={typ.id} value={typ.id}>
                      {FormatFineType(typ)}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  select
                  label="Статус"
                  size="small"
                  variant="outlined"
                  SelectProps={{
                    native: true,
                  }}
                  value={newStatus}
                  onChange={e => {
                    setNewStatus(e.target.value)
                  }}
                  disabled={!canChangeStatus}
                  required
                >
                  {availableStatuses.map((status) => (
                    <option key={status} value={status}>
                      {FormatStatus(status)}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid item>
                <ChoosePerson
                  size="small"
                  label="Соавтор"
                  value={newCoauthor}
                  onChange={person => {
                    setNewCoauthor(person)
                  }}
                  variant="outlined"
                  disabled={!canChangeLocation}
                />
              </Grid>

              {false &&
                <Grid item>
                  <TextField
                    size="small"
                    label="Код"
                    variant="outlined"
                    value={newCode}
                    onChange={e => {
                      setNewCode(e.target.value)
                    }}
                    disabled={!canChangeCode}
                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                    required
                  />
                </Grid>
              }

              <Grid item>
                <ChooseArea
                  size="small"
                  label="Здание, этаж"
                  variant="outlined"
                  area={newArea}
                  onChooseArea={area => {
                    setNewArea(area)
                    setNewLine("")
                    setNewLocation(null)
                  }}
                  disabled={!canChangeLocation}
                  required
                />
              </Grid>

              <Grid item>
                <ChooseLine
                  size="small"
                  label="Линия"
                  variant="outlined"
                  area={newArea}
                  line={newLine}
                  onChooseLine={line => {
                    setNewLine(line)
                    setNewLocation(null)
                  }}
                  disabled={!canChangeLocation}
                  required
                />
              </Grid>

              <Grid item>
                <ChoosePlace
                  size="small"
                  label="Павильон"
                  variant="outlined"
                  area={newArea}
                  line={newLine}
                  location={newLocation}
                  onChooseLocation={loc => {
                    setNewLocation(loc)
                  }}
                  disabled={!canChangeLocation}
                  required
                />
              </Grid>

              {shouldShowComment &&
                <Grid item>
                  <TextField
                    size="small"
                    label="Комментарий"
                    variant="outlined"
                    minRows={2}
                    multiline={true}
                    disabled={!canChange}
                    value={updateComment}
                    onChange={e => {
                      setUpdateComment(e.target.value)
                    }}
                    required
                  />
                </Grid>
              }

              <Grid item>
                <LoadingButton
                  loading={updating}
                  variant="contained"
                  disabled={!hasChanges || !canChange}
                  type="submit">
                  Сохранить
                </LoadingButton>
              </Grid>
            </Stack>
          </Stack>
          <Typography variant="h5" sx={{mt: 3}}>История изменений</Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Номер</TableCell>
                <TableCell>Дата</TableCell>
                <TableCell>Пользователь</TableCell>
                <TableCell>Информация</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fineHistory.map((row) => (
                <TableRow
                  key={row.id}
                >
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{`${row.create_date} ${row.create_time}`}</TableCell>
                  <TableCell>{row.user.name}</TableCell>
                  <TableCell style={{whiteSpace: "pre-wrap"}}>{row.info}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      }
    </LoadingWrapper>
  )
}