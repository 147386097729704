import { User } from './User';
import { Location } from './Location';
import { FieldValues } from 'react-hook-form';

export interface FineReason {
    auto_fine_type_id: number;
    id: number;
    name: string;
}

export interface FineType {
    code: number;
    id: number;
    name: string;
}

export enum FineStatus {
    NonPaid = 'non_paid',
    Pool = 'pool',
    Repaid = 'repaid',
    Cancelled = 'cancelled',
}

export function mapFineStatusToLabel(status: FineStatus): string {
    switch (status) {
        case FineStatus.Cancelled:
            return 'Отменен';
        case FineStatus.NonPaid:
            return 'Не погашен';
        case FineStatus.Pool:
            return 'Пул';
        case FineStatus.Repaid:
            return 'Погашен';
        default:
            return '';
    }
}

export function mapFineStatusToColor(status?: FineStatus): string {
    switch (status) {
        case FineStatus.Cancelled:
            return '#E91E63';
        case FineStatus.Repaid:
            return '#4CAF50';
        default:
            return 'black';
    }
}

export interface Fine {
    code: number;
    create_date: string;
    create_time: string;
    creator_user: User;
    coauthor_user?: User;
    description: string;
    fine_reason: FineReason;
    fine_type: FineType;
    id: number;
    initial_code: number;
    last_user: User;
    location: Location;
    pass_photo: string;
    status: FineStatus;
    violation_photo: string;
}

export interface FineResponse {
    items: Fine[];
    total_count: number;
}

export interface FineHistoryItem {
    create_date: string;
    create_time: string;
    id: number;
    info: string;
    user: User;
}

export interface CreateFineForm {
    location: Location;
    reason: FineReason;
    images: File[];
    coauthor?: User;
    comment?: string;
}

export interface CreateFineDto {
    fine_type_id: number;
    fine_reason_id: number;
    location_id: number;
    photos: string[];
    description?: string;
    coauthor_user_id?: number;
}
