import React from "react";
import {Labeled, useRecordContext} from "react-admin";
import {Stack} from "@mui/material";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

export const FlagsField: React.FC = (props) => {
    const record = useRecordContext();

    return (
        <Labeled label="Флаги">
            <Stack
                direction="row"
                alignItems="center"
                sx={{width: '100%'}}
            >
                {record.in_absentia && <MarkAsUnreadIcon titleAccess='Заочно'/>}
                {record.online_store && <LocalGroceryStoreIcon titleAccess='Интернет-магазин'/>}
                {record.half_zone && <StrikethroughSIcon titleAccess='Зона 1/2'/>}
                {record.magnet_card && <CreditScoreIcon titleAccess='Магнитная карта'/>}
            </Stack>
        </Labeled>
    )
};