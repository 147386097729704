import { configureStore } from '@reduxjs/toolkit';
import { baseApi } from '../api';
import { authSlice } from './auth';
import { updateLocalStorageListenerMiddleware } from './middleware/updateLocalStorageListenerMiddleware';
import { appealsDialogSlice } from './appeals/appealsDialog';
import { appealsStatusFilterSlice } from './appeals/appealsStatusFilter';
import { refetchOnFilterChangeListenerMiddleware } from './middleware/refetchOnFilterChangeListenerMiddleware';
import { passesStatusFilterSlice } from './passes/passesStatusFilter';

export const store = configureStore({
    reducer: {
        [baseApi.reducerPath]: baseApi.reducer,
        [authSlice.name]: authSlice.reducer,
        [appealsStatusFilterSlice.name]: appealsStatusFilterSlice.reducer,
        [appealsDialogSlice.name]: appealsDialogSlice.reducer,
        [passesStatusFilterSlice.name]: passesStatusFilterSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(baseApi.middleware)
            .concat(updateLocalStorageListenerMiddleware.middleware)
            .concat(refetchOnFilterChangeListenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
