import { Stack } from '@mui/material';
import { Card } from './Card';
import { useGetPassesQuery } from '../../../api/passes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { PassStatusFilterState } from '../../../store/passes/passesStatusFilter';

export const CardList = () => {
    const userId = useSelector<RootState, number>(
        (state) => state.auth.userId!
    );
    const statusFilters = useSelector<RootState, PassStatusFilterState>(
        (state) => state.passesStatusFilterSlice
    );
    const { data: passes, isFetching } = useGetPassesQuery(
        { userId, status: statusFilters },
        {
            refetchOnMountOrArgChange: true,
        }
    );
    const emptyPasses = new Array(3).fill(0);

    return (
        <Stack spacing={2} p={1} pt={0}>
            {(passes ?? emptyPasses).map((pass, i) => (
                <Card
                    key={typeof pass === 'number' ? i : pass.id}
                    pass={typeof pass === 'number' ? undefined : pass}
                    isLoading={isFetching}
                />
            ))}
        </Stack>
    );
};
