import ChooseEntity from "./choose_entity";
import FinesService from "../../services/fines_service";


export default function ChooseFineReason(props) {
  return (
    <ChooseEntity
      requester={FinesService.findFineReasons}
      {...props}
    />
  );
}