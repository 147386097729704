export const StatusNonPaid = 'non_paid';
export const StatusPool = 'pool';
export const StatusCollection = 'collection';
export const StatusRepaid = 'repaid';
export const StatusCancelled = 'cancelled';

export const Statuses = [
  {
    'id': StatusNonPaid,
    'name': 'Не погашен',
  },
  {
    'id': StatusPool,
    'name': 'Пул',
  },
  {
    'id': StatusCollection,
    'name': 'recalculation',
  },
  {
    'id': StatusRepaid,
    'name': 'Погашен',
  },
  {
    'id': StatusCancelled,
    'name': 'Отменен',
  }];

export const PassStatusPending = 'pending';
export const PassStatusDone = 'done';
export const PassStatusCancelled = 'cancelled';
export const PassStatusDelayed = 'delayed';

export const PassStatuses = [
  {id: PassStatusPending, name: 'Не оформлена'},
  {id: PassStatusDone, name: 'Оформлен'},
  {id: PassStatusCancelled, name: 'Отменен'},
];

export const PassFlags = [
  {id: 'in_absentia', name: 'Заочно'},
  {id: 'online_store', name: 'Интернет-магазин'},
  {id: 'half_zone', name: 'Зона 1/2'},
  {id: 'magnet_card', name: 'Магнитная карта'},
];

export function FormatStatus(id) {
  const status = Statuses.find(el => el.id === id);
  return status ? status.name : '';
}

export function FormatFineType(fineType) {
  return `${fineType.code}`;
  // if (fineType.code > 0) {
  //   return `${fineType.name} (код ${fineType.code})`
  // }
  // return fineType.name
}

export function FormatFineReason(fineReason) {
  return fineReason.name;
}

export function FormatError(error) {
  if (error.response) {
    if (error.response.status === 403) {
      return 'Недостаточно прав';
    } else if (error.response.status === 500) {
      return 'Ошибка сервера';
    } else if (error.response.data && error.response.data.message) {
      return error.response.data.message;
    } else {
      return 'Неизвестная ошибка';
    }
  } else if (error.request) {
    return 'Нет соединения с сервером';
  } else {
    return 'Неизвестная ошибка';
  }
}

export function FormatRole(role) {
  if (role === 'author') {
    return 'Автор';
  } else if (role === 'administrator') {
    return 'Администратор';
  } else if (role === 'cashier') {
    return 'Кассир';
  }
  return '';
}

export function FormatFineCollectionStatus(status) {
  if (status === 'pending') {
    return 'в процессе';
  } else if (status === 'complete') {
    return 'завершена';
  } else if (status === 'cancelled') {
    return 'отменена';
  }
  return '';
}

export function FormatJobTitle(pass) {
  let s = '';
  if (pass.partner_type) {
    s += pass.partner_type.name;
  }
  if (pass.job_title) {
    s += ' - ' + pass.job_title.name;
  }
  return s;
}

export function DownloadFile(blob, filename) {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}

