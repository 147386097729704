import React, { FC, useState } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import { ClickAwayListener, Tooltip } from '@mui/material';

interface IconTooltipProps {
    title: string;
    Icon: SvgIconComponent;
}

export const IconTooltip: FC<IconTooltipProps> = ({ Icon, title }) => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                title={title}
                arrow
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
            >
                <Icon
                    onClick={handleTooltipOpen}
                    sx={{ color: 'rgba(0, 0, 0, 0.38)', width: 14, height: 14 }}
                />
            </Tooltip>
        </ClickAwayListener>
    );
};
