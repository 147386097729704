import {useEffect, useState} from "react";
import FinesService from "../services/fines_service"
import LoadingWrapper from "../components/legacy/loading_screen";
import {
  Box,
  Button,
  FormControl, FormHelperText, IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField, Tooltip,
  Typography
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {DownloadFile, FormatError, FormatFineCollectionStatus} from "../util";
import {useNavigate} from "react-router-dom";
import {DatePicker} from "@mui/x-date-pickers";
import SummarizeIcon from "@mui/icons-material/Summarize";

export default function AdminFinesCollectionHistory() {
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)

  const queryParams = () => {
    return {
      date_from: dateFrom ? dateFrom.format("L") : "",
      date_to: dateTo ? dateTo.format("L") : "",
    }
  }

  const loadData = async () => {
    setLoading(true)

    try {
      setRows(await FinesService.collections(queryParams()))
    } catch (e) {
      setLoadingError(true)
    } finally {
      setLoading(false)
    }
  }

  const exportData = async () => {
    try {
      const data = await FinesService.collectionsExport(queryParams())
      DownloadFile(data, `recalculation-${(new Date()).toLocaleString("ru-RU")}.xlsx`)
    } catch (e) {
    } finally {
    }
  }

  useEffect(() => {
    loadData().catch(console.error)
  }, [dateFrom, dateTo])

  return (
    <Box>
      <Typography variant="h6" align="center">Журнал recalculation</Typography>
      <Stack direction="row" sx={{flexShrink: 1, mb: 2}} alignContent="center" alignItems="end" justifyContent="center">
        <FormControl>
          <FormHelperText>Дата, с</FormHelperText>
          <DatePicker
            renderInput={(props) => <TextField size="small" {...props} />}
            label=""
            value={dateFrom}
            onChange={(newValue) => {
              setDateFrom(newValue)
            }}
          />
        </FormControl>

        <FormControl sx={{ml: 1}}>
          <FormHelperText>по</FormHelperText>
          <DatePicker
            renderInput={(props) => <TextField size="small" {...props} />}
            label=""
            value={dateTo}
            onChange={(newValue) => {
              setDateTo(newValue)
            }}
          />
        </FormControl>

        <FormControl sx={{ml: 1}} style={{marginBottom: "4px"}}>
          <Tooltip title="Выгрузить в excel" arrow placement="bottom">
            <IconButton size="small" component="span" onClick={exportData}>
              <SummarizeIcon/>
            </IconButton>
          </Tooltip>
        </FormControl>
      </Stack>
      <LoadingWrapper loading={loading} error={loadingError}>
        {rows.length === 0 &&
          <Typography variant="body2" align="center">Recalculation не найдены</Typography>
        }
        {rows.length > 0 &&
          <Stack direction="column" alignContent="center" alignItems="center">
            <Table size="small" sx={{maxWidth: "800px", mb: 2}}>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Дата</TableCell>
                  <TableCell>Причина</TableCell>
                  <TableCell>Локация</TableCell>
                  <TableCell>Код</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return [
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row" colSpan="5"
                                 sx={{backgroundColor: (theme) => theme.palette.grey[200]}}>
                        <Typography variant="body">
                          <b>{`#${row.id}`}</b>
                          {` - ${row.created_at} - ${FormatFineCollectionStatus(row.status)}`}
                        </Typography>
                      </TableCell>
                    </TableRow>,
                    row.fines.map((fine) => (
                      <TableRow key={`fine-${fine.id}`}>
                        <TableCell component="th" scope="row">
                          {fine.id}
                        </TableCell>
                        <TableCell>{`${fine.create_date} ${fine.create_time}`}</TableCell>
                        <TableCell>{fine.fine_reason.name}</TableCell>
                        <TableCell>{fine.location.name}</TableCell>
                        <TableCell>{fine.code}</TableCell>
                      </TableRow>
                    ))
                  ]
                })}
              </TableBody>
            </Table>
            {
              (dateFrom == null || dateTo == null) &&
              <Typography variant="body2"
                          sx={{color: (theme) => theme.palette.grey[500]}}
                          align="center">
                Показаны последние 20 recalculation
              </Typography>
            }
          </Stack>
        }
      </LoadingWrapper>
    </Box>
  )
}