import instance from './api';
import TokenService from './token_service';
import history from '../routes/history';

const setupInterceptors = () => {
// for multiple requests
  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  instance.interceptors.request.use(
      (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token && !config.headers['Authorization']) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
  );

  instance.interceptors.response.use(function(response) {
    return response;
  }, function(error) {

    const originalRequest = error.config;

    if (error.response && error.response.status === 401 &&
        !originalRequest._retry) {

      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({resolve, reject});
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return instance(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = TokenService.getLocalRefreshToken();
      return new Promise(function(resolve, reject) {
        instance.post('/refresh_token', {'refresh_token': refreshToken}).
            then(({data}) => {
              TokenService.updateTokens(data.access_token, data.refresh_token);
              instance.defaults.headers.common['Authorization'] = 'Bearer ' +
                  data.access_token;
              originalRequest.headers['Authorization'] = 'Bearer ' +
                  data.access_token;
              processQueue(null, data.access_token);
              resolve(instance(originalRequest));
            }).
            catch((err) => {
              processQueue(err, null);
              history.replace('/ui/mobile_app/login');
              TokenService.removeUser();
              reject(err);
            }).
            finally(() => {
              isRefreshing = false;
            });
      });
    }

    return Promise.reject(error);
  });
};
export default setupInterceptors;