import instance from "./api";

export class LocationsService {
  async findLocations(name) {
    if (name === "") {
      return [];
    }

    const res = await instance.get(`/locations/?name=${name}`)
    return res.data
  }

  async findAreas(search) {
    if (search === "") {
      return [];
    }

    const res = await instance.get(`/locations/areas?search=${search}`)
    return res.data
  }

  async findLines(building, floor, search) {
    if (building === "" || floor === "" || search === "") {
      return [];
    }

    const res = await instance.get(`/locations/lines?building=${building}&floor=${floor}&search=${search}`)
    return res.data
  }

  async findPlaces(building, floor, line, search) {
    if (building === "" || floor === "" || line === "" || search === "") {
      return [];
    }

    const res = await instance.get(`/locations/places?building=${building}&floor=${floor}&line=${line}&search=${search}`)
    return res.data
  }
}

