import {BooleanField, Datagrid, EditButton, List, ShowButton, TextField} from "react-admin";
import React from "react";


export const LocationList = () => (
  <List>
    <Datagrid>
      <TextField source="id" label="ID"/>
      <TextField source="building" label="Здание"/>
      <TextField source="floor" label="Этаж"/>
      <TextField source="line" label="Линия"/>
      <TextField source="place_number" label="Точка"/>
      <TextField source="zone" label="Зона"/>
      <BooleanField source="active" label="Активна"/>
      <ShowButton/>
    </Datagrid>
  </List>
);