import {createContext} from "react";
import FineTypesAPI from "./api/fine_types_api";

export const GlobalState = createContext()

export const DataProvider = ({children}) => {
  const statedData = {
    fineTypesAPI: FineTypesAPI(),
  }

  return (
    <GlobalState.Provider value={statedData}>
      {children}
    </GlobalState.Provider>
  )
}
