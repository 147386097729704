import api from './api'
import TokenService from './token_service'

class AuthService {
  async login (login, password, tokenType) {
    const res = await api.post('/login', {
      'login': login,
      'password': password,
      'token_type': tokenType,
    })

    if (res.data) {
      TokenService.setUser(res.data)
    }
    return res.data
  }

  logout () {
    TokenService.removeUser()
  }
}

export default new AuthService()