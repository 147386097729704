import React, { useEffect, useState } from 'react';
import { Area, Line, Location } from '../models/Location';
import {
    useLazyGetAreasQuery,
    useLazyGetLinesQuery,
    useLazyGetPlacesQuery,
} from '../api/location';
import { usePrevious } from './usePrevious';

function useArea() {
    const [searchArea, setSearchArea] = useState('');
    const [area, setArea] = useState<Area | null>(null);
    const [triggerAre, { data: areaSuggest }] = useLazyGetAreasQuery();

    useEffect(() => {
        if (searchArea.length === 0) {
            return;
        }

        triggerAre(searchArea);
    }, [searchArea, triggerAre]);

    return {
        area,
        setArea,
        searchArea,
        setSearchArea,
        areaSuggest: searchArea.length > 0 ? areaSuggest : [],
    };
}

function useLine(area: Area | null) {
    const [searchLine, setSearchLine] = useState('');
    const [line, setLine] = useState<Line | null>(null);
    const [triggerSearchLines, { data: linesSuggest }] = useLazyGetLinesQuery();
    const [ capture, setCapture ] = useState<string>('');
    const prevArea = usePrevious(area);

    useEffect(() => {
        if (searchLine.length === 0 || area === null) {
            return;
        }

        triggerSearchLines({ search: searchLine, area });
    }, [searchLine, triggerSearchLines, area]);

    useEffect(() => {
        if (area !== prevArea && !capture) {
            setLine(null);
            setSearchLine('');
        }
    }, [area, prevArea, capture]);

    return {
        line,
        setLine,
        searchLine,
        setSearchLine,
        setCapture,
        linesSuggest: searchLine.length > 0 ? linesSuggest : [],
    };
}

function usePlace(area: Area | null, line: Line | null) {
    const [searchPlace, setSearchPlace] = useState('');
    const [place, setPlace] = useState<Location | null>(null);
    const [triggerSearchPlaces, { data: placeSuggest }] =
        useLazyGetPlacesQuery();
    const [ capture, setCapture ] = useState<string>('');

    const prevArea = usePrevious(area);
    const prevLine = usePrevious(line);

    useEffect(() => {
        if (searchPlace.length === 0 || area === null || line === null) {
            return;
        }

        triggerSearchPlaces({ search: searchPlace, area, line });
    }, [searchPlace, triggerSearchPlaces, area, line]);

    useEffect(() => {
        if ((area !== prevArea || line !== prevLine) && !capture) {
            setPlace(null);
            setSearchPlace('');
        }
    }, [area, prevArea, line, prevLine, capture]);

    return {
        searchPlace,
        setSearchPlace,
        place,
        setPlace,
        setCapture,
        placeSuggest: searchPlace.length > 0 ? placeSuggest : [],
    };
}

export function useLocation() {
    const area = useArea();
    const line = useLine(area.area);
    const place = usePlace(area.area, line.line);

    return {
        area,
        line,
        place,
    };
}
