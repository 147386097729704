import { Box, Stack, styled, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import AuthService from '../services/auth_service'
import { useNavigate } from 'react-router-dom'
import { FormatError } from '../util'
import logo from '../images/logo.png'
import { ToggleButton, ToggleButtonGroup } from '@mui/lab'

const LoginBox = styled('div')(({ theme }) => ({
  maxWidth: '330px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  background: 'white',
  padding: '20px',
  marginTop: '30px',
  '& .login__input': {
    marginBottom: theme.spacing(2),
  },
}))

export default function Login () {
  const [tokenType, setTokenType] = useState('mobile')
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const navigate = useNavigate()

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      await AuthService.login(login, password, tokenType)
      setError(false)
      setLoading(false)
      navigate('/ui', { replace: true })
    } catch (e) {
      setErrorText(FormatError(e))
      setError(true)
      setLoading(false)
    }
  }

  return (
    <Box component="form" onSubmit={onSubmit}>
      <LoginBox>
        <Stack direction="column" alignItems="center">
          <Box component="img" src={logo} sx={{ width: '150px' }}/>
        </Stack>

        {error &&
          <Typography
            textAlign="center"
            variant="body2"
            sx={{ mb: 2, color: (theme) => theme.palette.error.main }}
            gutterBottom
          >{errorText}</Typography>}

        <ToggleButtonGroup
          color="primary"
          value={tokenType}
          onChange={(e, newValue) => {
            if (newValue !== null) {
              setTokenType(newValue)
            }
          }}
          size="small"
          sx={{ mb: 2 }}
          fullWidth
          exclusive
        >
          <ToggleButton value="mobile" color="error">Моб. версия</ToggleButton>
          <ToggleButton value="admin" color="primary">Админка</ToggleButton>
        </ToggleButtonGroup>

        <TextField
          className="login__input"
          label="Логин"
          variant="outlined"
          value={login}
          disabled={loading}
          required
          size="small"
          onChange={e => setLogin(e.target.value)}
        />
        <TextField
          label="Пароль"
          className="login__input"
          type="password"
          variant="outlined"
          value={password}
          disabled={loading}
          required
          size="small"
          onChange={e => setPassword(e.target.value)}
        />
        <LoadingButton
          loading={loading}
          variant="contained"
          fullWidth
          type="submit"
        >
          Войти
        </LoadingButton>
      </LoginBox>
    </Box>
  )
}