import { FC } from 'react';
import { Box, Button, InputAdornment, Stack, TextField } from '@mui/material';
import { Add, QrCode, Search } from '@mui/icons-material';
import { FilterLabel } from './FilterLabel';
import { AppealsTable } from './AppealsTable';
import { CreateDialog } from './CreateDialog';
import { useDialog } from '../../../hooks/useDialog';
import { ViewerDialog } from './ViewerDialog';
import { FineStatus } from '../../../models/Fine';

export const Appeals: FC = () => {
    const [createPopupOpen, openCreatePopup, closeCreatePopup] = useDialog();

    return (
        <Stack>
            <Stack direction="row" spacing={1} p={1}>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="ФИО или пропуск"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                ></TextField>

                <Button
                    size="small"
                    variant="contained"
                    sx={{ minWidth: 'auto' }}
                >
                    <QrCode />
                </Button>
            </Stack>
            <Stack
                direction="row"
                spacing={1}
                px={1}
                pb={2}
                sx={{ overflowX: 'auto' }}
            >
                <FilterLabel status={FineStatus.NonPaid} label="Не погашен" />
                <FilterLabel status={FineStatus.Cancelled} label="Отменён" />
                <FilterLabel status={FineStatus.Repaid} label="Погашен" />
                <FilterLabel status={FineStatus.Pool} label="Пул" />
            </Stack>
            <Box
                sx={{
                    overflowY: 'auto',
                    position: 'absolute',
                    top: '6rem',
                    bottom: '6rem',
                    left: 0,
                    right: 0,
                }}
            >
                <AppealsTable />
            </Box>
            <Button
                variant="contained"
                size="large"
                sx={{
                    position: 'absolute',
                    left: '1rem',
                    right: '1rem',
                    bottom: '7rem',
                }}
                onClick={openCreatePopup}
            >
                <Stack direction="row" spacing={1}>
                    Создать обращение
                    <Add />
                </Stack>
            </Button>

            <CreateDialog open={createPopupOpen} onClose={closeCreatePopup} />
            <ViewerDialog />
        </Stack>
    );
};
