import {Autocomplete, TextField, Typography} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import throttle from 'lodash/throttle';
import {LocationsService} from "../../services/locations_service";

const autocompleteService = {current: null};

export default function ChooseArea(
  {
    onChooseArea,
    required,
    disabled,
    size,
    area
  }) {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const label = "Здание, этаж"

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.findAreas(request.input).then(callback);
      }, 200),
    [area],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current) {
      autocompleteService.current = new LocationsService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(area ? [area] : []);
      return undefined;
    }

    fetch({input: inputValue}, (results) => {
      if (active) {
        let newOptions = [];

        if (area) {
          newOptions = [area];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [area, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : `${option.building} ${option.floor}`
      }
      disabled={disabled}
      filterOptions={(x) => x}
      size={size}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={area}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onChooseArea(newValue);
      }}
      noOptionsText="Не найдено"
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth required={required}/>
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={`${option.building} ${option.floor}`}>
            <Typography variant="body2" color="text.secondary">
              {`${option.building} ${option.floor}`}
            </Typography>
          </li>
        );
      }}
    />
  );
}