import {
  Outlet, Routes, Route, useMatch, Link as RouterLink, useNavigate,
} from 'react-router-dom';
import {DataProvider} from '../GlobalState';
import {Box, Breadcrumbs, Link, Paper, Stack, Typography} from '@mui/material';
import TokenService from '../services/token_service';
import {FormatRole} from '../util';

const BreadcrumbsWrapper = () => {
  const finesMatches = useMatch('/ui/admin/fines');
  const finesCollectionMatches = useMatch('/ui/admin/fines/collection');
  const finesCompleteCollectionMatches = useMatch(
      '/ui/admin/fines/complete_collection');
  const finesCollectionHistoryMatches = useMatch(
      '/ui/admin/fines/collection_history');
  let finesDetailsMatches = useMatch('/ui/admin/fines/:id');

  if (finesCollectionMatches || finesCompleteCollectionMatches ||
      finesCollectionHistoryMatches) {
    finesDetailsMatches = null;
  }

  return (
      <Breadcrumbs>
        {finesMatches && (
            <span key="el">Обращения</span>
        )}
        {finesDetailsMatches && [
          <Link key="root" component={RouterLink} to={`/ui/admin/fines`}>
            Обращения
          </Link>,
          <span key="el">#{finesDetailsMatches.params.id}</span>,
        ]}
        {finesCollectionMatches && [
          <Link key="root" component={RouterLink} to={`/ui/admin/fines`}>
            Обращения
          </Link>,
          <span key="el">Recalculation</span>,
        ]}
        {finesCompleteCollectionMatches && [
          <Link key="root" component={RouterLink} to={`/ui/admin/fines`}>
            Обращения
          </Link>,
          <span key="el">Принять recalculation</span>,
        ]}
        {finesCollectionHistoryMatches && [
          <Link key="root" component={RouterLink} to={`/ui/admin/fines`}>
            Обращения
          </Link>,
          <span key="el">Журнал recalculation</span>,
        ]}
      </Breadcrumbs>
  );
};

export default function AdminWrapper() {
  const user = TokenService.getUser();
  const navigate = useNavigate();

  const logout = (e) => {
    e.preventDefault();
    if (window.confirm('Вы действительно хотите выйти?')) {
      TokenService.removeUser();
      navigate('/ui/mobile_app/login', {replace: true});
    }
  };

  function openNewAdmin(e) {
    e.preventDefault();
    window.open('/ui/admin_new', '_blank');
  }

  return (
      <Box
          sx={{
            width: '100%',
            height: '100%',
            p: 2,
            backgroundColor: 'rgb(231, 235, 240)',
            overflowY: 'scroll',
          }}
      >
        <Stack direction="row" justifyContent="space-between" sx={{mb: 2}}>
          <Routes>
            <Route path="*" element={<BreadcrumbsWrapper/>}/>
          </Routes>
          <Stack direction="row" alignContent="center" alignItems="center">
            <Typography variant="body2">
              <a onClick={openNewAdmin} href="#" target={'_blank'}>
                Админка для визиток
              </a>
              &nbsp;
              -
              &nbsp;
              {user &&
                  `${user.name} - ${FormatRole(user.role)}`
              }
              &nbsp;(<a href="#" onClick={logout}>выход</a>)
            </Typography>
          </Stack>

        </Stack>

        <DataProvider>
          <Paper sx={{p: 2, backgroundColor: '#fff'}}>
            <Outlet/>
          </Paper>
        </DataProvider>
      </Box>
  );
}