import {Autocomplete, TextField, Typography} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import throttle from 'lodash/throttle';

export default function ChooseEntity(
  {
    onChange,
    required,
    disabled,
    label,
    size,
    value,
    requester,
  }) {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        requester(request.input).then(callback);
      }, 200),
    [value],
  );

  useEffect(() => {
    let active = true;
    //
    // if (inputValue === '') {
    //   setOptions(value ? [value] : []);
    //   return undefined;
    // }

    fetch({input: inputValue}, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.name
      }
      disabled={disabled}
      filterOptions={(x) => x}
      size={size}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onChange(newValue);
      }}
      noOptionsText="Не найдено"
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth required={required}/>
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <Typography variant="body2" color="text.secondary">
              {option.name}
            </Typography>
          </li>
        );
      }}
    />
  );
}