import { FC } from 'react';
import { FineStatus } from '../../../models/Fine';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { setAppealsStatusFilter } from '../../../store/appeals/appealsStatusFilter';
import { FilterChip } from '../common/FilterChip';

interface FilterLabelProps {
    label: string;
    status: FineStatus;
}

export const FilterLabel: FC<FilterLabelProps> = ({ label, status }) => {
    const selected = useSelector<RootState, boolean>(
        (state) => state.appealsStatusFilterSlice[status]
    );
    const dispatch = useDispatch();
    const select = () =>
        dispatch(
            setAppealsStatusFilter({
                status,
                state: true,
            })
        );
    const unselect = selected
        ? () =>
              dispatch(
                  setAppealsStatusFilter({
                      status,
                      state: false,
                  })
              )
        : undefined;

    return (
        <FilterChip
            label={label}
            selected={selected}
            onClick={select}
            onDelete={unselect}
        />
    );
};
