/**
 * Plural forms for russian words
 * @param  {Number} count quantity for word
 * @param  {Array} words Array of words. Example: ['депутат', 'депутата', 'депутатов'], ['коментарий', 'коментария', 'комментариев']
 * @return {String}        Count + plural form for word
 */
export function pluralize(count: number, words: [string, string, string]) {
    const cases = [2, 0, 1, 1, 1, 2];
    return (
        count +
        ' ' +
        words[
            count % 100 > 4 && count % 100 < 20
                ? 2
                : cases[Math.min(count % 10, 5)]
        ]
    );
}

export function joinByKeys<T extends {}>(status: T): string {
    return Object.entries(status)
        .filter(([_key, value]) => !!value)
        .map(([key]) => key)
        .join(',');
}
