import { baseApi } from './index';
import { IPartner, IPartnerType } from 'src/models/Partner';

export const partnerApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getPartner: builder.query<IPartner[], string>({
            query: (search) => ({
                url: 'partners',
                params: {
                    search,
                },
            }),
        }),
        getPartnerTypes: builder.query<IPartnerType[], any>({
            query: () => ({
                url: 'partner_types',
            }),
        }),
        getPartnerTypesSmart: builder.query<IPartnerType[], any>({
            query: () => ({
                url: 'partner_types_smart',
            }),
        }),
    }),
});

export const {
    useLazyGetPartnerQuery,
    useGetPartnerTypesQuery,
    useGetPartnerTypesSmartQuery,
} = partnerApi;
