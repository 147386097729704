import { Dialog } from '@material-ui/core';
import React, { useState } from 'react';
import moment from "moment";
import {
    Box,
    Stack,
    TextField,
  } from "@mui/material";
import { Button, useRecordContext } from 'react-admin';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import PassService from 'src/services/pass_service';

interface IProps {
    onClose: () => void;
    request: (data: any) => void;
}
export const ExtendDialog: React.FC<IProps> = ({ onClose, request }) => {
    const [date, setDate] = useState<any>(moment());
    const record = useRecordContext();

    const handleSubmit = async () => {
        
        const validUntil = moment(date).toISOString();

        if (!validUntil) {
            return false;
        }

        request({
            data: {
                id: record.id,
                params: {
                    valid_until: validUntil
                }
            },
            fetch: PassService.passExtend,
            onFinish: onClose
        })
    }

    return (
        <Dialog open>
            <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
                <Box sx={{
                    padding: '1rem 2rem'
                }}>
                    <Stack width={'100%'} sx={{ mb: 2, flexShrink: 1 }}>
                        <DatePicker
                            renderInput={(props) => <TextField size="small" {...props} fullWidth />}
                            label=""
                            value={date}
                            onChange={(newValue) => {
                                setDate(newValue)
                            }}
                            minDate={moment()}
                        />
                    </Stack>

                    <Stack direction="row" sx={{ gap: '1rem' }} >
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            size="large"
                            startIcon={<CheckIcon />}
                            label='Продлить'
                        />
                        <Button
                            onClick={onClose}
                            variant="contained"
                            size="large"
                            sx={{
                                backgroundColor: 'salmon'
                            }}
                            startIcon={<CancelIcon />}
                            label='Отмена'
                        />
                    </Stack>
                </Box>
            </LocalizationProvider>
        </Dialog>
    )
}