import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import BuildIcon from '@mui/icons-material/Build';
import { MenuItem } from './MenuItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { LayoutGroup } from 'framer-motion';
import { logout } from '../../../../store/auth';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { LogoutOutlined } from '@mui/icons-material';

const routes = new Map([
    [0, '../appeals'],
    [1, '../cards'],
    [2, '../sos'],
    [3, '../lion'],
    [4, '../more'],
]);
const routeToIndex = new Map(
    Array.from(routes.entries()).map(([key, value]) => [value, key])
);

export const Menu = () => {
    const location = useLocation();
    const path = '../' + location.pathname.split('/').reverse()?.[0];
    const currentIndex = routeToIndex.get(path) ?? 0;
    const [active, setActive] = useState(currentIndex);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const exit = () => {
        if (window.confirm('Вы действительно хотите выйти?')) {
            dispatch(logout());
        }
    };

    useEffect(() => {
        navigate(routes.get(active)!, { replace: true });
    }, [active, navigate]);

    return (
        <LayoutGroup>
            <Stack sx={{ height: 1 }} direction="row" spacing={1} px={1}>
                <MenuItem
                    index={0}
                    activeIndex={active}
                    setActive={setActive}
                    Icon={ReceiptIcon}
                    label="Обращения"
                />
                <MenuItem
                    index={1}
                    activeIndex={active}
                    setActive={setActive}
                    Icon={PermContactCalendarIcon}
                    label="Визитки"
                />
                <MenuItem
                    index={2}
                    activeIndex={active}
                    setActive={setActive}
                    Icon={LocalHospitalIcon}
                    label="SOS"
                />
                <MenuItem
                    index={3}
                    activeIndex={active}
                    setActive={setActive}
                    Icon={BuildIcon}
                    label="Лайон"
                />
                <MenuItem
                    index={4}
                    activeIndex={active}
                    setActive={exit}
                    Icon={LogoutOutlined}
                    label="Выход"
                />
            </Stack>
        </LayoutGroup>
    );
};
