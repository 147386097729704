import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React, { FC, useEffect } from 'react';
import { AddPhotoAlternateOutlined, ArrowBack } from '@mui/icons-material';
import { SlideUpTransition } from '../common/SlideUpTransition';
import { usePersonSuggest } from '../../../hooks/usePersonSuggest';
import {
    useForm,
    Controller,
    Control,
    useWatch,
    useController,
} from 'react-hook-form';
import { CreateFineForm } from '../../../models/Fine';
import { useFormReset } from '../../../hooks/useFormReset';
import { useFineReasonSuggest } from '../../../hooks/useFineResonSuggest';
import { Images } from './Images';
//import Promise from '@mui/x-data-grid/lib/lodash/_Promise';
import {
    useCreateFineMutation,
    useUploadPhotoMutation,
} from '../../../api/fine';
import { LocationForm } from '../common/LocationForm';
import { requiredRule } from '../../../models/Form';

interface CreateDialogProps {
    open: boolean;
    onClose: () => void;
}

interface FormControl {
    control: Control<CreateFineForm>;
    error?: any;
}

export const CreateDialog: FC<CreateDialogProps> = ({ open, onClose }) => {
    const { control, handleSubmit, reset, formState: { errors }, setValue} = useForm<CreateFineForm>();
    const [upload, { isLoading: isFileUploading }] = useUploadPhotoMutation();
    const [create, { isLoading: isCreating }] = useCreateFineMutation();

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={SlideUpTransition}
        >
            <form
                onSubmit={handleSubmit(async (form) => {
                    const photos = await Promise.all(
                        form.images.map((file) => upload(file).unwrap())
                    );

                    await create({ form, photos }).unwrap();

                    reset();
                    onClose();
                })}
            >
                <Stack direction="column" p={2} spacing={3}>
                    <Button
                        onClick={onClose}
                        variant="contained"
                        sx={{ minWidth: 'auto', width: '3rem' }}
                    >
                        <ArrowBack />
                    </Button>

                    <ImageUpload control={control} error={errors.images}/>

                    <LocationForm
                        qrCode={true}
                        control={control}
                        name="location"
                        setValue={setValue}
                    />

                    <Reason control={control} />

                    <CoAuthor control={control} />

                    <Controller
                        name="comment"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                id="description"
                                label="Описание"
                                minRows={3}
                                fullWidth
                                multiline
                                {...field}
                            />
                        )}
                    />

                    <Button
                        variant="contained"
                        size="large"
                        type="submit"
                        disabled={isFileUploading || isCreating}
                    >
                        Создать
                    </Button>
                </Stack>
            </form>
        </Dialog>
    );
};

const ImageUpload: FC<FormControl> = ({ control, error }) => {
    const imagesControl = useController({
        control,
        name: 'images',
        defaultValue: [],
        rules: {required: true}
    });

    const onChange = async (files: FileList | null) => {
        if (!files) {
            return;
        }

        imagesControl.field.onChange([
            ...imagesControl.field.value,
            ...Array.from(files),
        ]);
    };

    return (
        <Stack spacing={1}>
            <Stack direction="row">
                <Images
                    images={imagesControl.field.value.map((file) =>
                        URL.createObjectURL(file)
                    )}
                >
                    <Box
                        sx={{
                            width: 64,
                            height: 64,
                            border: error
                                ? '1px solid #d32f2f'
                                : '1px dashed rgba(0, 0, 0, 0.12)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: '0 0 auto',
                        }}
                    >
                        <IconButton
                            size="large"
                            color="primary"
                            component="label"
                            disableRipple
                            sx={{
                                background: 'rgba(25, 118, 210, 0.12)',
                            }}
                        >
                            <input
                                hidden
                                accept="image/*"
                                multiple
                                type="file"
                                onChange={(e) => onChange(e.target.files)}
                            />
                            <AddPhotoAlternateOutlined />
                        </IconButton>
                    </Box>
                </Images>
            </Stack>

            <Typography
                sx={{ margin: '-0.5rem 0 !important' }}
                variant="caption"
                display="block"
                style={error && { color: '#d32f2f' }}
            >
                Добавьте фото нарушителя, пропуска и нарушения*
            </Typography>
        </Stack>
    );
};

const Reason: FC<FormControl> = ({ control }) => {
    const reason = useFineReasonSuggest();
    const value = useWatch({ control, name: 'coauthor' });

    useFormReset(value, () => {
        reason.setReason(null);
        reason.setSearchReason('');
    });

    return (
        <Controller
            control={control}
            rules={requiredRule}
            name="reason"
            render={({
                field: { onChange, ...field },
                formState: { errors },
            }) => (
                <Autocomplete
                    fullWidth
                    size="small"
                    id="reason"
                    options={reason.reasonSuggest ?? []}
                    loadingText="Загрузка..."
                    noOptionsText="Ничего не найдено"
                    getOptionLabel={(option) => option.name}
                    onChange={(e, r) => {
                        onChange(r);
                        reason.setReason(r);
                    }}
                    isOptionEqualToValue={(o, v) => o.id === v.id}
                    onInputChange={(e, v) => {
                        reason.setSearchReason(v);
                    }}
                    value={reason.reason}
                    inputValue={reason.searchReason}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            {...field}
                            label="Причина*"
                            error={!!errors.reason}
                            helperText={errors.reason?.message ?? ''}
                        />
                    )}
                />
            )}
        />
    );
};

const CoAuthor: FC<FormControl> = ({ control }) => {
    const coauthor = usePersonSuggest();
    const value = useWatch({ control, name: 'coauthor' });

    useFormReset(value, () => {
        coauthor.setPerson(null, null);
        coauthor.setSearchPerson('');
    });

    return (
        <Controller
            control={control}
            name="coauthor"
            render={({ field: { onChange, ...field } }) => (
                <Autocomplete
                    fullWidth
                    size="small"
                    id="coauthor"
                    options={coauthor.personSuggest ?? []}
                    loadingText="Загрузка..."
                    noOptionsText="Ничего не найдено"
                    getOptionLabel={(option) => option.name}
                    filterOptions={(x) => x}
                    onChange={(e, user) => {
                        onChange(user);
                        coauthor.setPerson(e, user);
                    }}
                    isOptionEqualToValue={(o, v) => o.id === v.id}
                    onInputChange={(e, v) => {
                        coauthor.setSearchPerson(v);
                    }}
                    value={coauthor.person}
                    inputValue={coauthor.searchPerson}
                    renderInput={(params) => (
                        <TextField {...params} {...field} label="Соавтор" />
                    )}
                />
            )}
        />
    );
};
