import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import TokenService, {PermissionCreateFine, TokenTypeAny, TokenTypeMobile} from "src/services/token_service";

export const RootRedirect = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const user = TokenService.getUser();
        if (!user) {
            navigate('/ui/mobile_app/login', {replace: true});
        } else {
            const tokenType = user.token_type;
            let useMobile = (tokenType === TokenTypeMobile) ||
                (tokenType === TokenTypeAny &&
                    TokenService.hasPermission(PermissionCreateFine));

            if (useMobile) {
                navigate('/ui/mobile_app');
            } else {
                navigate('/ui/admin/fines');
            }
        }
    });

    return (
        <span/>
    );
};